import * as React from "react"
import {
  Datagrid,
  DatagridConfigurable,
  DateField,
  FunctionField,
  List,
  Loading,
  ReferenceField,
  TextField,
  TopToolbar,
  downloadCSV,
  useGetIdentity,
} from "react-admin"

import ParentEdit from './ParentEdit'
import ParentsShow from './ParentsShow'
import { getRole } from '../common/roles'
import { exporter } from "./ParentsModelExport"
import ParentsListFilter from "./ParentsListFilter"
import { PostPagination } from '../common/Pagination'
import DisplayPhoneNumber from "../Design/DisplayPhoneNumber"
import { ParentsActions, ListToolbar } from "./ParentsComponents"
import { YellowReferenceField } from "../Design/CustomReferenceField"

const ParentList = props => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  
  return identity ? (
    <>
      <List
        {...props}
        empty={false}
        pagination={<PostPagination />}
        perPage={50}
        actions={<ParentsActions exporter={exporter} />}
        aside={isAdmin ? <ParentsListFilter /> : null}
        filter={ isAdmin ? {} : { center_id: identity?.center?.id } }
        sort={{ field: 'created_at', order: 'DESC' }}
      >
          <ListToolbar />

          <DatagridConfigurable
            rowClick={`${identity?.role.grade <= getRole('pubeur') ? 'show': 'edit'}`}
            omit={['created_at']}
            bulkActionButtons={
              identity?.role?.grade >= getRole('responsable') ? true : false
            }
          >
              <TextField label="Prénom" source="first_name" />
              <TextField label="Nom" source="last_name" />
              <TextField label="Email" source="email" />

              {/* <YellowReferenceField label="Pubeur assigné" source="assigned_pubeur" reference="profiles">
                <FunctionField render={record => (
                  `${record.first_name} ${record.last_name}`
                )} />
              </YellowReferenceField> */}

              <DisplayPhoneNumber label="Téléphone" source="phone" />

              <ReferenceField label="Centre" source="center_id" reference="centers">
                <TextField source="name" />
              </ReferenceField>

              <DateField label="Date de création" source="created_at" showTime={true} />
          </DatagridConfigurable>
      </List>
      
      <ParentEdit />
    </>
  ) : <Loading />
}

export default ParentList

import { supabaseClient } from '../supabase'
import { getValidEmail, getValidPhone, toCamelCase, toCapitalCase } from '../common/Formating'

export const CheckIfEventExistsInContact = async (leadId, eventId) => {
  const { data, error } = await supabaseClient.from('lead_events')
    .select()
    .eq('lead_id', leadId)
    .eq('event_id', eventId)
    .eq('current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)

  if (error) {
    console.error('Error fetching lead events: ', error)
    return true
  }

  return {
    event: data[0],
    res: data.length > 0
      ? true
      : false
  }
}

export const checkForDuplicates = async (leadId, phone, email, center_id) => {
  if ( ! center_id ) return console.error('Center ID is missing')
  
  const { data, error } = await supabaseClient.rpc('get_duplicate_leads', { p_phone: phone, p_email: email, p_center_id: center_id })

  if ( error ) {
    console.error('Error fetching duplicate leads: ', error)
    console.error('Info received: ', leadId, phone, email, center_id)
    return
  }

  return await data.filter(lead => lead.id !== leadId)[0]
}

export const checkForDuplicatesParents = async (parentId, phone, email, center_id) => {
  const { data, error } = await supabaseClient.rpc('get_duplicate_parents', { p_phone: phone, p_email: email, p_center_id: center_id })

  if (error) {
    console.error('Error fetching duplicate parents: ', error)
    return
  }

  return await data.filter(parent => parent.id !== parentId)[0]
}

export const checkForDuplicatesImport = async (inboundContact) => {
  const column = inboundContact.lead_id ? 'lead_id' : 'parent_id'
  const uuid = inboundContact.lead_id ? inboundContact.lead_id : inboundContact.parent_id
  const forma_id = inboundContact.forma_id

  const { data, error } = await supabaseClient
    .from('inbound_contacts')
    .select()
    .or(`forma_id.eq.${forma_id},${column}.eq.${uuid}`)

  if (error) {
    console.error('Error fetching duplicate import: ', error)
    return
  }

  return {
    result: data.length > 0 ? true : false,
    data: data[0]
  }
}

export const transformData = (data, user, roleUser) => {
  const {
    id,
    assigned_pubeur,
    email,
    phone,
    hot_list_bool,
    first_name,
    last_name,
    lead_source_id,
    fts,
    ...rest
  } = data

  return {
    id: id,
    phone: getValidPhone(phone),
    email: getValidEmail(email),
    assigned_pubeur: id
      ? ( hot_list_bool
            ? user?.id
            : assigned_pubeur )
      : ( roleUser < 20 ? user?.id : null ),
    first_name: toCamelCase(first_name),
    last_name: toCapitalCase(last_name),
    lead_source_id: lead_source_id ?? process.env.REACT_APP_LEAD_SOURCE_WEB,
    ...rest,
  }
}

export const insertIntoTable = async (tableName, data) => {
  const { data: result, error } = await supabaseClient
    .from(tableName)
    .upsert(data)
    .single()
    .select()

  if (error) {
    return error.code === '23505'
      ? console.log('Contact '+ data.lead_id +' already registered')
      : console.error(error)
  }

  return { result }
}

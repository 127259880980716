import {
  Autocomplete,
  TextField,
} from '@mui/material';

export const YearSelectInput = ({ label, minWidth, ...rest }) => {
  const handleValidation = (currentYear) => {
    if ( currentYear === null ) return

    localStorage.setItem('localCurrentYear', JSON.stringify(currentYear))
    localStorage.removeItem('groupedFilteredValues')
    localStorage.removeItem('filterValuesBeforeGrouped')
    window.location.reload(true)
  }

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.id}
      sx={{ minWidth: minWidth }}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder={label} />}
      {...rest}
      onChange={(event, newValue) => handleValidation(newValue ?? null)}
    />
  )
}

export const CustomSelectInput = ({ label, minWidth, ...rest }) => (
  <Autocomplete
    disablePortal
    autoComplete
    includeInputInList
    getOptionLabel={(option) => option.label}
    isOptionEqualToValue={ (option, value) => option.value === value.value }
    sx={{ minWidth: minWidth }}
    renderInput={(params) => <TextField {...params} variant="outlined" placeholder={label} />}
    {...rest}
  />
)
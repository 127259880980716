import { useEffect, useState } from 'react'
import {
  useRecordContext,
  useSaveContext,
  DeleteButton,
  SaveButton,
  Toolbar,
} from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'
import { getRole } from '../common/roles'
import { set } from 'lodash'
import { getItemFromLocalStorage } from '../common/LocalStorage'

const UserEditToolbar = ({autosave, gradeRole, isDisabled, props}) => {
  const record = useRecordContext(props)

  const localCurrentYear = getItemFromLocalStorage('localCurrentYear') || process.env.REACT_APP_CURRENT_YEAR_ID

  // const [isDisabled, setIsDisabled] = useState(localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true)
  const { saving } = useSaveContext()

  const handleSaveClick = () => {
    return;


  }

  return (
    <>
      { record?.id && (
        <div style={{ position: 'absolute', top: 70, right: 20 }}>
          { autosave ? <CircularProgress /> : <SaveButton
            label=""
            disabled={isDisabled}
            onClick={handleSaveClick}
          /> }
        </div>
      )}

      <Toolbar sx={{ ml: '10px', mr: '10px', borderRadius: '8px', mb: 2, }}>
        <SaveButton
          disabled={isDisabled}
          onClick={handleSaveClick}
        />

        { gradeRole >= getRole('responsable') && (
          <DeleteButton
            style={{ position: 'absolute', right: 10 }}
            disabled={ localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
          />
        )}
      </Toolbar>
    </>
  )
}

export default UserEditToolbar
import * as React from 'react'
import { Card, Typography, Stack } from '@mui/material'

import DashboardPubeurLeads from './DashboardPubeurLeads'
import DashboardPubeurTasks from './DashboardPubeurTasks'
import DashboardPubeurEvents from './DashboardPubeurEvents'
import DashboardPubeurFrais from './DashboardPubeurFrais'

import DashboardIcon from '@mui/icons-material/Dashboard'

export default () => {
  return (
    <Card sx={{ mt: 4, p: 2, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px' }}>
      <Typography variant="h4" sx={{ borderBottom: '1px dotted #ccc' }}>
        <DashboardIcon /> Mon tableau de bord
      </Typography>

      {/* <Stack spacing={2} mt={4} direction="row" justifyContent="space-between"> */}
      <Stack spacing={2} mt={4} direction="row" justifyContent="flex-left">
        <DashboardPubeurLeads />
        <DashboardPubeurTasks />
        <DashboardPubeurEvents />
        <DashboardPubeurFrais />
      </Stack>
    </Card>
  )
}
import { useEffect, useMemo, useRef, useState } from 'react'
import { Button, useList, Datagrid, ListContextProvider, TextField, FunctionField, } from 'react-admin'
import { Stack, InputAdornment, IconButton, TextField as MUITextField, Pagination as MUIPagination } from '@mui/material'

import sendMail from '../common/SendMail'
import { CommonDialog } from '../common/Dialog'

import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
import { supabaseClient } from '../supabase'

async function fetchFilteredProfiles(eventId, keyword) {
  const { data, error } = await supabaseClient.rpc('get_profiles_not_in_event', {
    _event_id: eventId,
    _keyword: keyword || ''
  })

  if (error) {
    console.error('Error fetching profiles in fetchFilteredProfiles:', error.message)
    return []
  }

  return data
}

const useRefreshProfiles = (eventInfo, search = '') => {
  const [profiles, setProfiles] = useState([])

  const refresh = async (search) => {
    const fetchedProfiles = await fetchFilteredProfiles(eventInfo.id, search)
    setProfiles(fetchedProfiles)
  }

  return [profiles, refresh]
}

const EventProfileSelectDialog = ({ open, onClose, eventInfo, listRefresh, profilesRefresh, profiles }) => {
  const perPage = 10
  const totalPages = Math.ceil(profiles.length / perPage)
  const [page, setPage] = useState(1)
  const searchRef = useRef()

  const paginatedData = useMemo(() => {
    return profiles.slice((page - 1) * perPage, page * perPage)
  }, [profiles, page, perPage])

  const listContext = useList({
    data: paginatedData,
    ids: paginatedData.map(profile => profile.id),
    total: profiles.length,
    perPage: perPage,
    page: page,
    setPage: setPage,
  })

  const [search, setSearch] = useState('')

  const handleSearchChange = async (e) => {
    const query = e.target.value ?? ''

    setSearch(query)
    setPage(1)
    profilesRefresh(query)
  }

  const handleSearchClear = () => {
    setSearch('')
    setPage(1)
    profilesRefresh('')
  }

  const addPubeurToEvent = async (profile) => {
    // Add the profile to the event
    if ( ! profile ) return

    const pubeurInfo = profile

    const { error } = await supabaseClient
      .from('event_pubeurs')
      .insert([{ event_id: eventInfo.id, pubeur_id: pubeurInfo.id }])
    
    if (error) {
      console.error('Error adding pubeur to event:', error.message)
      return
    }

    if ( eventInfo.type_id === process.env.REACT_APP_EVENT_TYPE_MISSIONLYCEE ) {
      // send email to the pubeur
      console.log('Envoi de mail au pubeur...');
      
      const messageHTML = `
        <h3>Nouvelle assignation à un événement</h3>

        <h4>Vous avez été assigné(e) à un nouvel événement aujourd'hui.</h4>

        <h2>${eventInfo.name}</h2>

        <p>
          <a href="https://forma-contacts.fr/events/${eventInfo.id}">Cliquez ici pour le consulter.</a>
        </p>

        <p>
          <i>Ceci est un message automatique, merci de ne pas y répondre.</i>
        </p>
      `

      const messageTEXT = `NOUVELLE ASSIGNATION À UN ÉVÉNEMENT\n\nVous avez été assigné(e) à un nouvel événement aujourd'hui : ${eventInfo.name}\n\nCliquez ici pour le consulter : https://forma-contacts.fr/events/${eventInfo.id}\n\nCeci est un message automatique, merci de ne pas y répondre.`
      
      sendMail ({
        identity: pubeurInfo,
        subject: `Nouvelle assignation à un événement`,
        messageHTML: messageHTML,
        messageTEXT: messageTEXT,
        date: new Date().toISOString(),
        sendNow: true,
      })
    }

    // Refresh the profiles list
    profilesRefresh(search)
    handleSearchClear()
    listRefresh()
  }

  useEffect(() => {
    if (open && searchRef.current) {
      searchRef.current.focus()
    }
  }, [searchRef.current, search])

  return (
    <CommonDialog
      open={open}
      handleClose={onClose}
      title="Liste des pubeurs"
    >
      <MUITextField
        placeholder="Recherche..."
        value={search}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleSearchClear}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputRef={searchRef}
      />

      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <TextField label="Prénom" source="first_name" />
          <TextField label="Nom" source="last_name" />
          <TextField label="Email" source="email" />
          <TextField source="role_name" label="Rôle" />

          <FunctionField render={profile => (
            <Button
              label="Ajouter"
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                addPubeurToEvent(profile)
              }}
            />
          )} />
        </Datagrid>

        <MUIPagination
          count={totalPages}
          page={page}
          onChange={(_, value) => setPage(value)}
          color="primary"
          showFirstButton
          showLastButton
        />
      </ListContextProvider>
    </CommonDialog>
  )
}

const EventProfileSelect = ({ eventInfo, listRefresh }) => {
  const [open, setOpen] = useState(false)
  const [profiles, refreshProfiles] = useRefreshProfiles(eventInfo)

  const handleClose = () => {
    setOpen(false)
  }

  const handleRefresh = (search = '') => {
    refreshProfiles(search)
  }

  useEffect(() => {
    if (!open) return
    handleRefresh()
  }, [open])

  return (
    <>
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Button
          label="Ajouter pubeur(s)"
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => setOpen(true)}
          color="primary"
          sx={{ mt: 2 }}
        />
      </Stack>

      <EventProfileSelectDialog
        open={open}
        onClose={handleClose}
        eventInfo={eventInfo}
        listRefresh={listRefresh}
        profilesRefresh={handleRefresh}
        profiles={profiles}
      />
    </>
  )
}

export default EventProfileSelect
import { useState, useEffect } from 'react'
import { Typography, Stack, Button, Card, CardContent, CardMedia, List, ListItem, ListItemText } from '@mui/material'

import Avatar from '@mui/material/Avatar'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import TaskIcon from '@mui/icons-material/Task'
import { useGetIdentity } from "react-admin"
import { supabaseClient } from "../../supabase"

const DashboardPubeurTasks = () => {
  const { identity } = useGetIdentity()
  const [pubeurTasks, setPubeurTasks] = useState([])

  useEffect(() => {
    const getAllTasksPubeur = async () => {
      const { data, error } = await supabaseClient
        .from('lead_tasks')
        .select('progress, leads (assigned_pubeur), tasks (name)')
        .eq('leads.assigned_pubeur', identity?.id)
  
      if (error) {
        console.error(error)
        return
      }

      const taskSummary = data.reduce((acc, item) => {
        if ( ! item?.tasks ) return acc
        
        const { name } = item?.tasks
        const progress = item?.progress
        
        // Initialize the task entry in the accumulator if it doesn't exist
        if ( ! acc[name] ) {
          acc[name] = {
            name,
            completed: 0,
            waiting: 0,
            not_started: 0
          }
        }
        
        // Determine the status of the task and increment the appropriate counter
        if ( progress === '100' ) {
          acc[name].completed++
        } else if ( progress > 0 && progress < 100 ) {
          acc[name].waiting++
        } else {
          acc[name].not_started++
        }
      
        return acc
      }, {})

      setPubeurTasks(Object.values(taskSummary))
    }

    getAllTasksPubeur()
  }, [])

  return (
    <Card sx={{ width: '33%', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px' }}>
      <CardMedia
        component="img"
        alt="tâches attribuées"
        height="140"
        image="https://merriam-webster.com/assets/mw/images/article/art-wap-article-main/can-task-be-a-verb-5813-7075c71d8cf734c3c83e9edf76bc66fb@1x.jpg"
        sx={{ opacity: .75 }}
      />

      <CardContent>
        <Typography variant="h6" gutterBottom>
          Tâches attribuées
        </Typography>

        <List dense={true}>
          { pubeurTasks.map((task, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: '#91bcba' }}>
                  <TaskIcon />
                </Avatar>
              </ListItemAvatar>

              <ListItemText primary={task.name} secondary={
                [
                  task.completed > 0 ? `Complétées : ${task.completed}` : '',
                  task.waiting > 0 ? `En cours : ${task.waiting}` : '',
                  task.not_started > 0 ? `En attente : ${task.not_started}` : ''
                ].filter(Boolean).join(', ')
              } />
            </ListItem>
          )) }
        </List>
      </CardContent>
    </Card>
  )
}

export default DashboardPubeurTasks
import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  Button,
  useRefresh,
  useNotify,
  warning,
  useGetIdentity,
  SimpleForm,
  DateInput,
  Toolbar,
  SaveButton,
} from 'react-admin'
import { Typography } from '@mui/material'
import DOMPurify from 'dompurify'

import isInt from '../common/isInt'
import stripos from '../common/stripos'
import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import { CommonDialog } from '../common/Dialog'
import fetchEvents from '../common/fetchEvents'
import { getClassIdByName } from '../common/classes'
import { availableOptions } from '../common/options'
import addNewComment from '../common/addLeadComment'
import arraysDeepEqual from '../common/arrayComparison'
import { CustomSelectInput } from '../Design/CustomSelectInput'
import { RotatingIcon as LoadingIcon } from '../common/RotatingIcon'
import { getValidEmail, getValidPhone, toCamelCase, toCapitalCase } from '../common/Formating'
import { CheckIfEventExistsInContact, checkForDuplicates, checkForDuplicatesParents, checkForDuplicatesImport, insertIntoTable } from '../leads/LeadUtils'

import UploadIcon from '@mui/icons-material/Upload'

export const ImportBrochuresButton = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  const localCenter = identity?.center

  const [open, setOpen] = useState(false)
  const [importSourceFromSelect, setImportSourceFromSelect] = useState(null)
  const [dateFromSelect, setDateFromSelect] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchedCenterEvents, setFetchedCenterEvents] = useState(null)
  const [totalRecords, setTotalRecords] = useState(0)
  const [nmbrOfImport, setNmbrOfImport] = useState(0)

  useEffect(() => {
    const getData = async () => {
      const data = await fetchEvents(localCenter?.id, identity)
      
      setFetchedCenterEvents(data)
    }

    getData()
  }, [localCenter])
  
  if ( ! fetchedCenterEvents ) return

  const createNewLead = async (row) => {
    /* for contact question parcoursup */
    const q_parcoursup = row.contact.q_parcoursup
    const universite_parcoursup = row.contact.universite_parcoursup
    const classement_parcoursup = row.contact.classement_parcoursup
    const mineure_parcoursup = row.contact.mineure_parcoursup
    const choix_parcoursup = row.contact.choix_parcoursup

    delete row.contact.q_parcoursup
    delete row.contact.universite_parcoursup
    delete row.contact.classement_parcoursup
    delete row.contact.mineure_parcoursup
    delete row.contact.choix_parcoursup

    /* for IC requested date */
    const requested_date = row.contact.requested_date // we need to keep it to display it in IC
    delete row.contact.requested_date // delete before saving to leads

    /* add new lead */
    const addNewLead = async () => {
      console.log('Ajout nouveau lead: ', row.contact.last_name, row.contact.first_name)

      const insertNewLead = await insertIntoTable('leads', row.contact)
      
      if ( ! insertNewLead ) {
        console.error('Erreur lors de la création du lead: ', insertNewLead.error)
        notify('Erreur lors de la création du lead', { type: 'warning' })
        setIsLoading(false)
        return null
      }

      return await insertNewLead.result.id ?? null
    }

    const lead_id = await addNewLead()

    if (! lead_id) {
      console.error('Lead ID is undefined after addNewLead execution.')
      return
    }

    /* add new event */
    let insertNewEvent
    if ( row.event.event_id ) {
      console.log('Ajout nouvel événement sur nouveau contact')

      row.event.lead_id = lead_id

      try {
        insertNewEvent = await insertIntoTable('lead_events', row.event)
      } catch (error) {
        console.error('Erreur lors de la création de l\'événement: ', error)
      }
    }

    /* add comment to lead */
    addNewComment(row.event?.lead_id, identity?.id, row.event?.event_id, `*AUTO* Contact ajouté depuis contacts entrants (ID ${row.contact.lead_source_id}`)

    if ( q_parcoursup ) {
      addNewComment(row.event.lead_id, identity?.id, row.event?.event_id,
        `*QUESTION PARCOURSUP* ${q_parcoursup} || Université : ${universite_parcoursup} || Classement : ${classement_parcoursup} || Choix : ${choix_parcoursup} || Mineure : ${mineure_parcoursup}`
      )
    }
    
    setNmbrOfImport(prevNmbrOfImport => prevNmbrOfImport + 1)

    row.contact.requested_date = requested_date // we put it back to get the info in IC

    return { contact_id: lead_id, lead_event_id: insertNewEvent?.id }
  }

  const createNewParent = async (contact) => {
    console.log('Ajout nouveau parent: ', contact.last_name, contact.first_name)

    const requested_date = contact.requested_date // we need to keep it to display it in IC
    delete contact.requested_date // delete before saving to leads

    const insertNewParent = await insertIntoTable('parents', contact)
    
    if ( ! insertNewParent ) {
      console.error('Erreur lors de la création du parent :', insertNewParent.error)
      notify('Erreur lors de la création du parent', { type: warning })
      setIsLoading(false)
      return null
    }
    
    setNmbrOfImport(prevNmbrOfImport => prevNmbrOfImport + 1)

    contact.requested_date = requested_date // we put it back to get the info in IC
    
    return { contact_id: insertNewParent.result.id }
  }

  const transformToInboundContact = ( contact, isAlreadyRegistered, type, requested_date, source ) => {
    if ( ! contact.id ) {
      console.error('ID is required but missing for transformToInboundContact :', contact)
      return
    }

    const transformedData = {
      forma_id: contact.forma_id ?? null,
      requested_date: requested_date ?? null,
      lead_id: type === 'lead' ? contact.id : null,
      parent_id: type === 'parent' ? contact.id : null,
      center_id: contact.center_id ?? '7c9a8f3b-4557-0137-2fe9-2722d6eb0e27', // global
      already_registered: isAlreadyRegistered ?? false,
      source_id: type = source ?? null,
      status: 'new',
      comment: null,
      last_action: null,
      classe: contact.classe ?? null,
      lycee: contact.lycee ?? null,
      formation: contact.formation ?? null,
      first_name: contact.first_name ?? null,
      last_name: contact.last_name ?? null,
      year_id: contact.current_year_id ?? process.env.REACT_APP_CURRENT_YEAR_ID,
    }
  
    return transformedData 
  }

  const handleRowImport = async (importation) => {
    const leadPhone = importation.tel ?? importation.phone ?? ''
    const leadMail = importation.mail ?? importation.email ?? ''

    const contact = { // formated for leads/parents table
      date: importation.date ?? null,
      last_name: toCapitalCase(importation.last_name) ?? null,
      first_name: toCamelCase(importation.first_name) ?? null,
      phone: leadPhone !== '' ? getValidPhone(leadPhone) : null,
      email: leadMail !== '' ? getValidEmail(leadMail) : null,
      address: {
        'street': importation.street ?? '',
        'postalCode': importation.postal_code ?? '',
        'city': importation.city ?? '',
      },
      class_id: importation.class_id ?? null,
      center_id: importation.center_id ?? '7c9a8f3b-4557-0137-2fe9-2722d6eb0e27', // global
      lead_source_id: importation.source_id ?? null,
      current_year_id: importation.current_year_id ?? process.env.REACT_APP_CURRENT_YEAR_ID,
      q_parcoursup: importation.question ?? null, // delete before saving to leads
      universite_parcoursup: importation.universite ?? null, // delete before saving to leads
      classement_parcoursup: importation.classement ?? null, // delete before saving to leads
      mineure_parcoursup: importation.mineure ?? null, // delete before saving to leads
      choix_parcoursup: importation.choix ?? null, // delete before saving to leads
    }

    const event = {
      event_id: importation.event_id ?? null,
      current_year_id: importation.current_year_id ?? process.env.REACT_APP_CURRENT_YEAR_ID,
    }

    const row = {
      contact: contact,
      event: event,
      info: {
        demandeur: importation.demandeur === 'Parent' ? 'parent' : 'lead',
        consider: true // default
      }
    }

    // adapt for parents
    if ( row.info.demandeur === 'parent' ) {
      contact.source_id = contact.lead_source_id
      delete contact.lead_source_id // no lead_source_id for parents, only source_id
      delete contact.class_id // no class for parents
      delete contact.q_parcoursup // no q_parcoursup for parents
      delete contact.universite_parcoursup // no universite_parcoursup for parents
      delete contact.classement_parcoursup // no classement_parcoursup for parents
      delete contact.mineure_parcoursup // no mineure_parcoursup for parents
      delete contact.choix_parcoursup // no choix_parcoursup for parents
    }

    const checkForDoublons = async (row, newPhone, newEmail, newCenterId) => {
      const isExisting = row.info.demandeur === 'parent'
        ? await checkForDuplicatesParents(null, newPhone, newEmail, newCenterId)
        : await checkForDuplicates(null, newPhone, newEmail, newCenterId)

      if ( isExisting ) {
        console.log('Contact déjà enregistré:', isExisting.first_name, isExisting.last_name, '('+ importation.demandeur ?? 'étudiant' +')')

        // update contact with information
        if ( isExisting.id ) {
          delete row.contact.q_parcoursup // delete before updating
          delete row.contact.universite_parcoursup // delete before updating
          delete row.contact.classement_parcoursup // delete before updating
          delete row.contact.mineure_parcoursup // delete before updating
          delete row.contact.choix_parcoursup // delete before updating

          const UpdateExistingLead = async () => {
            // delete empty rows
            const contactBis = contact
            Object.keys(contactBis).forEach(key => {
              if ( ! contactBis[key] ) {
                delete contactBis[key]
              }
            })

            delete contactBis.date // date is not in leads table
            delete contactBis.lead_source_id // source_id must NOT be updated

            // update lead
            const tableToUpdate = row.info.demandeur === 'parent' ? 'parents' : 'leads'
            const { error: error } = await supabaseClient
              .from(tableToUpdate)
              .update(contactBis)
              .eq('id', isExisting.id)

            if ( error ) {
              console.error('Error updating lead '+ isExisting.id +':', error)
            }

            if ( row.info.demandeur !== 'parent' ) {
              // log previous data in a comment
              addNewComment(isExisting.id, identity?.id, null,
                `*AUTO* Contact mis à jour depuis un import contacts entrants.
                Données d'origine :
                [Prénom : ${isExisting.first_name}],
                [Nom : ${isExisting.last_name}],
                [Téléphone : ${isExisting.phone}],
                [Email : ${isExisting.email}],
                [Adresse : ${isExisting.address.street}, ${isExisting.address.postalCode} ${isExisting.address.city}],
                [Classe : ${isExisting.class_id}].`
              )

              if ( importation.question ) {
                addNewComment(isExisting.id, identity?.id, null,
                  `*QUESTION PARCOURSUP* ${importation.question} || Université : ${importation.universite} || Classement : ${importation.classement} || Choix : ${importation.choix} || Mineure : ${importation.mineure}`
                )
              }
            }
          }

          await UpdateExistingLead()
        }
      }
      
      return isExisting ? isExisting.id : null
    }

    // finally, get contact id IF already existing in leads / parents
    const getContactId = async () => {
      if ( row.contact.phone === '' && row.contact.email === '' ) {
        return null // cannot check doublons without phone or email
      }

      const newPhone = getValidPhone(row.contact.phone) ?? null
      const newEmail = getValidEmail(row.contact.email) ?? null
      const newCenterId = importation.center_id ?? localCenter?.id

      const foundId = await checkForDoublons(row, newPhone, newEmail, newCenterId)

      return foundId
    }
    
    const contactId = await getContactId()
    row.contact.id = contactId
    row.requested_date = importation.created_at ?? importation.inscription ?? importation.date ?? null

    return row
  }

  const handleRefresh = async (selectedImportSource, sourceToFetch, importSource, importEvent) => {
    setNmbrOfImport(0)

    try {
      let response
      let dataRetrieved

      if ( selectedImportSource === 'demande de brochures' ) {
        /* NOT WORKING DUE TO CORS POLICY WITH SERVICEWORKER
        const username = process.env.REACT_APP_API_ACCESS_BROCHURES_CLIENT
        const password = process.env.REACT_APP_API_ACCESS_BROCHURES_KEY

        const credentials = `${username}:${password}`
        const encodedCredentials = typeof Buffer !== 'undefined' 
        ? Buffer.from(credentials).toString('base64') 
        : btoa(unescape(encodeURIComponent(credentials))) // Fallback for browser environments

        const headers = new Headers()
        headers.set('Authorization', 'Basic ' + encodedCredentials)
        
        response = await fetch(sourceToFetch, { headers: headers })*/

        response = await fetch(sourceToFetch)
        dataRetrieved = await response.json()

        const updateKey = (data) => {
          Object.keys(data).forEach(key => {
            data[key].forEach(entry => {
              if (entry.id && ! entry.forma_id) {
                  entry.forma_id = DOMPurify.sanitize(entry.id)
                  delete entry.id
              }

              if (entry.crm_id) {
                  entry.center_id = DOMPurify.sanitize(entry.crm_id)
                  delete entry.crm_id
              }

              if (entry.crm_annee) {
                entry.current_year_id = DOMPurify.sanitize(entry.crm_annee)
                delete entry.crm_annee
              }

              if (entry.prenom) {
                entry.first_name = DOMPurify.sanitize(entry.prenom)
                delete entry.prenom
              }

              if (entry.nom) {
                entry.last_name = DOMPurify.sanitize(entry.nom)
                delete entry.nom
              }

              if (entry.date) {
                entry.created_at = DOMPurify.sanitize(entry.date)
                delete entry.date
              }

              if (entry.classe) {
                entry.class_id = isInt(entry.classe) ? getClassIdByName(entry.classe, localCenter) : null

                if ( ! entry.class_id ) { // fallback
                  entry.class_id = getClassIdByName('Autre', localCenter)
                }
              }

              entry.lead_source_id = importSource
            })
          })
      
          return data
        }

        dataRetrieved = updateKey(dataRetrieved)
      }

      if ( selectedImportSource === 'demande de rappel' ) {
        response = await fetch(sourceToFetch)
        dataRetrieved = await response.json()

        const updateKey = (data) => {
          Object.keys(data).forEach(key => {
            data[key].forEach(entry => {
              entry.forma_id = DOMPurify.sanitize(entry.forma_id)
              entry.center_id = DOMPurify.sanitize(entry.center_id)
              entry.current_year_id = process.env.REACT_APP_CURRENT_YEAR_ID
              entry.phone = DOMPurify.sanitize(entry.phone)
              entry.created_at = DOMPurify.sanitize(entry.created_at)
              entry.lead_source_id = importSource
            })
          })
      
          return data
        }

        dataRetrieved = updateKey(dataRetrieved)
      }

      if ( selectedImportSource === 'q_parcoursup' ) {
        response = await fetch(sourceToFetch)
        dataRetrieved = await response.json()

        const updateKey = (data) => {
          Object.keys(data).forEach(key => {
            data[key].forEach(entry => {
              if (entry.id && ! entry.forma_id) {
                entry.forma_id = DOMPurify.sanitize(entry.id)
                delete entry.id
              }

              if (entry.crm_id) {
                entry.center_id = DOMPurify.sanitize(entry.crm_id)
                delete entry.crm_id
              }

              if (entry.crm_annee) {
                entry.current_year_id = DOMPurify.sanitize(entry.crm_annee)
                delete entry.crm_annee
              } else {
                entry.current_year_id = process.env.REACT_APP_CURRENT_YEAR_ID
              }

              entry.question = DOMPurify.sanitize(entry.question)
              entry.universite = DOMPurify.sanitize(entry.universite)
              entry.classement = DOMPurify.sanitize(entry.classement)
              entry.mineure = DOMPurify.sanitize(entry.mineure)
              entry.choix = DOMPurify.sanitize(entry.choix)

              entry.lead_source_id = importSource
            })
          })
      
          return data
        }

        dataRetrieved = updateKey(dataRetrieved)
      }
      
      if ( selectedImportSource === 'NOMAD' ) {
        const fetching = sourceToFetch.split(',')
        const table = fetching[0]
        const dateMin = fetching[1]
        const centerId = fetching[2]

        const { data: dataNomad, error: error } = await supabaseClient
          .from(table)
          .select('*')
          .eq('center_id', centerId)
          .gte('date', dateMin +' 00:00:00.000+00')
        
        if ( error ) {
          console.error('Error fetching data from NOMAD: ', error)
          notify('Erreur lors de la récupération des données', { type: 'warning' })
          setIsLoading(false)
          return
        }

        const findFormationId = (formation) => {
          let checkFormation
          if ( stripos ( 'l.as', formation ) || stripos ( 'las', formation ) ) {
            checkFormation = 'l.as'
          } else if ( stripos ( 'pass', formation ) ) {
            checkFormation = 'pass'
          } else if ( stripos ( 'p0', formation ) || stripos ( 'las', formation ) ) {
            checkFormation = 'p0'
          } else if ( stripos ( 'terminale', formation ) ) {
            checkFormation = 'terminale'
          } else if ( stripos ( 'première', formation ) || stripos ( 'premiere', formation ) ) {
            checkFormation = 'première'
          } else {
            checkFormation = formation
          }
          
          for (const center in availableOptions) {
            if (center !== 'default' && center !== localCenter?.id) {
              continue
            }

            const option = availableOptions[center].options.find(opt => opt.name.includes(checkFormation))

            if (option) {
              return option.id
            }
          }

          const defaultOption = availableOptions['default'].options.find(opt => opt.name.includes(checkFormation));
          
          if (defaultOption) {
            return defaultOption.id
          }

          return formation
        }

        dataNomad.forEach(entry => {
          if ( entry.id && ! entry.forma_id ) {
            entry.forma_id = entry.id
          }

          if ( entry.classe ) {
            entry.class_id = getClassIdByName(entry.classe, localCenter)

            if ( ! entry.class_id ) { // fallback
              entry.class_id = getClassIdByName('Autre', localCenter)
            }

            // only for NOMAD before septembre :
            // keep the previous year for terminales and log them in 2023
            // move secondes and premières to the next class and log them in 2024
            const currentDate = new Date()
            const currentMonth = currentDate.getMonth()

            if ( currentMonth < 10 ) {
              entry.current_year_id = process.env.REACT_APP_CURRENT_YEAR_ID
              if ( entry.class_id === 3 ) {
                entry.current_year_id = 'e24834f3-d19a-4ad5-ad5d-85da26458f65'
              }

              if ( entry.class_id === 1 || entry.class_id === 2) {
                entry.class_id++
              }
            }
            // end of the special case for NOMAD

            delete entry.classe
          }

          if ( entry.formation_souhaitee ) {
            entry.formation = findFormationId(entry.formation_souhaitee);
          }

          entry.lead_source_id = importSource

          console.log('entry.current_year_id:', entry.current_year_id);
        })

        dataRetrieved = { [dataNomad[0].center_id]: dataNomad }
      }
      
      if ( selectedImportSource === 'diplomeo' ) {
        /* NOT WORKING DUE TO CORS POLICY WITH SERVICEWORKER
        const username = process.env.REACT_APP_API_ACCESS_BROCHURES_CLIENT
        const password = process.env.REACT_APP_API_ACCESS_BROCHURES_KEY

        const credentials = `${username}:${password}`
        const encodedCredentials = btoa(unescape(encodeURIComponent(credentials)))

        const headers = new Headers({
          'Authorization': `Basic ${encodedCredentials}`,
        })
        
        const response = await fetch(sourceToFetch, { headers: headers })*/

        // special because diplomeo sends contacts by page...
        const fetchDataByPage = async (url) => {
          const response = await fetch(url)

          if ( ! response.ok ) {
            console.error(`HTTP error! status: ${response.status}`);
          }

          const rawDataRetrieved = await response.json()

          if ( ! rawDataRetrieved ) {
            notify('Aucune donnée à importer', { type: 'success' })
            setIsLoading(false)
            return
          }

          return rawDataRetrieved
        }
        
        // Fetch data for mulitple pages
        let rawDataRetrievedForAllPages = []
        let previousDataForPage = []

        // loop by page
        for (let i = 1; i <= 999; i++) {
          const dataForPage = await fetchDataByPage(`${sourceToFetch}&page=${i}`)

          if  (dataForPage.length === 0 || arraysDeepEqual(dataForPage, previousDataForPage) ) {
            break
          }

          rawDataRetrievedForAllPages = [...rawDataRetrievedForAllPages, ...dataForPage]
          previousDataForPage = dataForPage
        }

        // then update the data
        const updateDiplomeoEntries = (data) => {
          return data.map(entry => {
            entry.forma_id = entry.diplomeo_id
            entry.city = entry.address?.City
            entry.postal_code = entry.address?.ZipCode
            entry.date = entry.creation_date

            let center_diplomeo = entry.center.toLowerCase().replace('é', 'e')

            if (["antemed", "epsilon"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = '9e9389ba-ddb3-3d97-4439-b217ace5ec63';
            } else if (["bersot", "besançon", "besancon"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = 'f2e82cbf-7639-b7b9-1293-a46a76fa8432';
            } else if (["cappec", "loos", "lille"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = 'e97ecbc3-18ab-a9ea-5a7c-cc30f22ee715';
            } else if (["cepsup", "betheny", "reims"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = 'a8641a0f-6195-27ac-066c-dd14de4eb7d5';
            } else if (["ipe", "poitiers"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = '059382f2-25bc-22e4-7db8-1c386e8db332';
            } else if (["myprepa"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = '5a645f5e-1cb3-4fb6-9a45-df94449715d2';
            } else if (["lyon", "paracelse"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = '5aa045fc-7da0-423f-98a5-13617d346e19';
            } else if (["defi", "angers"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = '383b98c6-d20f-bb7d-b84c-f9ab419c6d37';
            } else if (["vesale", "strasbourg"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = 'aae6b73f-d437-c0f2-ab7e-2000e8a79a64';
            } else if (["segmo", "rouen"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = 'bc55c1d1-0f37-0ec6-01f0-0dfec2e56ffc';
            } else if (["skol", "rennes"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = 'c5ce75b5-b902-6a5a-f2fa-e98002842711';
            } else if (["stan", "nancy", "metz"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = '3754543f-0341-fe8f-19b5-096473cb1a08';
            } else if (["dijon"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = '2b2bc81c-6375-4f94-acf3-eab3927186b0';
            } else if (["ortho"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = 'f72660a3-eb1d-4b9e-abf3-097eaad60e1f';
            } else if (["nantes"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = '2d4313f9-885f-d7b9-326f-210b9fd647d8';
            } else if (["paris"].some(substring => center_diplomeo.includes(substring))) {
              entry.center_id = 'b909af63-6b2c-4382-ab6a-7ad424e89f5b';
            } else {
              entry.center_id = localCenter?.id;
            }

            delete entry.diplomeo_id
            delete entry.Situation
            delete entry.StudyLevel
            delete entry.center
            delete entry.address

            entry.current_year_id = process.env.REACT_APP_CURRENT_YEAR_ID
            entry.lead_source_id = importSource

            return entry
          })
        }

        dataRetrieved = updateDiplomeoEntries(rawDataRetrievedForAllPages)

        let groupedByCenterId = {}
        dataRetrieved.forEach(record => {
          const centerId = record.center_id

          if (!groupedByCenterId[centerId]) {
            groupedByCenterId[centerId] = []
          }

          groupedByCenterId[centerId].push(record)
        })

        dataRetrieved = groupedByCenterId
      }

      if ( ! dataRetrieved ) {
        notify('Aucune donnée à importer', { type: 'success' })
        return
      }

      let totalRecords = 0

      for ( let key in dataRetrieved ) {
        totalRecords += dataRetrieved[key].length
        setTotalRecords(totalRecords)
      }

      console.log('Nombre total de contacts à importer:', totalRecords)

      for ( let key in dataRetrieved ) {
        const dataCentre = dataRetrieved[key]

        for ( let i = 0 ; i < dataCentre.length ; i++ ) {
          dataCentre[i].source_id = importSource ?? null
          dataCentre[i].event_id = importEvent ?? null

          const row = await handleRowImport(dataCentre[i])

          await Promise.all([row])

          if ( ! row ) {
            console.error('Erreur lors de la création du contact entrant:', row)
            console.log('_____________________')
            continue
          }

          let isAlreadyRegistered = true

          /* create lead or parent if not existing */
          if ( ! row.contact.id ) {
            console.log('Pas d\'ID, création du contact...')

            delete row.contact.id // to avoid key constraint error
            const saveDate = row.contact.date
            delete row.contact.date

            const createNewContact = row.info.demandeur === 'parent'
              ? await createNewParent(row.contact)
              : await createNewLead(row) // create new lead + event
            
            if ( ! createNewContact ) {
              console.error('Erreur lors de la création du contact:', createNewContact)
              console.log('_____________________')
              continue
            }
            
            row.event.lead_event_id = createNewContact?.lead_event_id
            row.contact.date = saveDate
            row.contact.id = createNewContact?.contact_id
            isAlreadyRegistered = false
          } else {
            await Promise.resolve()
          }

          if ( ! row.contact.id ) {
            console.error('Contact id is required but missing:', row.contact)
            continue
          }

          row.event.lead_id = row.contact.id

          row.inboundContact = transformToInboundContact(row.contact, isAlreadyRegistered, row.info.demandeur, row.requested_date, importSource)
          row.inboundContact.lead_event_id = row.event.lead_event_id ?? null
          row.inboundContact.forma_id = dataCentre[i].forma_id
            ? selectedImportSource.replace(' ', '_') +'_'+ dataCentre[i].forma_id // to avoid mixing up with other imports
            : null

          /* insert event to existing lead (not parent) */
          if ( isAlreadyRegistered && row.event.event_id && row.info.demandeur === 'lead' ) {
            if ( ! row.event.lead_id ) {
              console.error('lead_id not present for ', row.event)
            }
              
            const isEventInContact = await CheckIfEventExistsInContact ( row.event.lead_id, row.event.event_id )

            if ( ! isEventInContact?.res ) {
              console.log('Ajout nouvel événement sur contact déjà existant')
              const addToLead_events = await insertIntoTable ( 'lead_events', row.event )

              if ( ! addToLead_events ) {
                console.error('Erreur lors de la création de l\'événement: ', addToLead_events.error)
              }

              row.inboundContact.lead_event_id = addToLead_events?.id ?? null
            } else {
              console.log('Événement déjà présent dans le contact')
              row.inboundContact.lead_event_id = isEventInContact?.event?.id
            }
          }

          /* finally, check if inbound contact already present */
          if ( row.inboundContact.lead_id || row.inboundContact.parent_id ) {
            const isAlreadyRegisteredAsInbound = await checkForDuplicatesImport(row.inboundContact)
            
            if ( isAlreadyRegisteredAsInbound.result ) {
              console.log('Contact entrant déjà enregistré')

              // we update the information
              const updateInboundContact = await supabaseClient
                .from('inbound_contacts')
                .update(row.inboundContact)
                .eq('id', isAlreadyRegisteredAsInbound.data.id)

              if ( updateInboundContact.error ) {
                console.error('Erreur lors de la mise à jour du contact entrant:', updateInboundContact.error)
              } else {
                console.log('Contact entrant mis à jour')
              }

              console.log('_____________________')
              continue
            }

            console.log('Ajout aux contacts entrants')
            const insertNewIC = await insertIntoTable('inbound_contacts', row.inboundContact)
          
            if ( ! insertNewIC ) {
              console.error('Erreur lors de la création du lead', insertNewIC.error)
              notify('Erreur lors de l\'enregistrement du contact entrant', { type: warning })
              setIsLoading(false)
              return
            }
          } else {
            console.error('lead_id or parent_id is missing:', row.inboundContact)
          }

          console.log('_____________________')
        }

        console.log('Importation terminée')
      }

      setIsLoading(false)
    } catch (error) {
      notify(error.message, { type: warning });
      setIsLoading(false)
    } finally {
      if ( ! isLoading ) {
        notify('Contacts entrants importés avec succès', { type: 'success' })
        setOpen(false)
        refresh()
      }
    }
  }

  const HandleImportBrochures = () => {
    const selectedImportSource = importSourceFromSelect
    const dateMin = dateFromSelect

    if ( ! selectedImportSource ) {
      notify('Vous devez précider une source pour l\'import', { type: 'alert' })
      return
    }

    setIsLoading(true)

    let tempImportSource = null
    let tempImportEvent = null
    let tempSourceToFetch = null

    if ( selectedImportSource === 'demande de brochures' ) {
      console.log('Importation "Demandes de brochures"...')
      tempImportEvent = fetchedCenterEvents.find(event => event.name.toLowerCase().includes('demande de brochures'))?.id ?? process.env.REACT_APP_EVENT_GLOBAL_BROCHURES
      tempImportSource = process.env.REACT_APP_LEAD_SOURCE_IMPORT_BROCHURES ?? null
      tempSourceToFetch = isAdmin ? `https://event.formapriv.fr/gen_json_brochures.php?min_date=${dateMin}` : `https://event.formapriv.fr/gen_json_brochures.php?min_date=${dateMin}&center_id=${localCenter?.id}`
    }
    
    if ( selectedImportSource === 'NOMAD' ) {
      console.log('Importation "NOMAD"...')
      tempImportEvent = fetchedCenterEvents.find(event => event.name.toLowerCase().includes('import nomad'))?.id ?? process.env.REACT_APP_EVENT_GLOBAL_NOMAD
      tempImportSource = process.env.REACT_APP_LEAD_SOURCE_IMPORT_NOMAD ?? null
      tempSourceToFetch = `nomad_leads,${dateMin},${localCenter?.id}`
    }
    
    if ( selectedImportSource === 'diplomeo' ) {
      console.log('Importation "Diplomeo"...')
      tempImportEvent = fetchedCenterEvents.find(event => event.name.toLowerCase().includes('contact diplomeo'))?.id ?? process.env.REACT_APP_EVENT_GLOBAL_DIPLOMEO ?? null
      tempImportSource = process.env.REACT_APP_LEAD_SOURCE_IMPORT_DIPLOMEO ?? null
      tempSourceToFetch = `https://sms.formapriv.fr/sms/diplomeo.php?date_min=${dateMin}`
    }

    if ( selectedImportSource === 'q_parcoursup' ) {
      console.log('Importation "Questions Parcousup"...')
      tempImportEvent = fetchedCenterEvents.find(event => event.name.toLowerCase().includes('question parcoursup'))?.id ?? process.env.REACT_APP_EVENT_GLOBAL_Q_PARCOURSUP ?? null
      tempImportSource = process.env.REACT_APP_LEAD_SOURCE_IMPORT_Q_PARCOURSUP ?? null
      tempSourceToFetch = isAdmin ? `https://event.formapriv.fr/gen_json_questions.php?min_date=${dateMin}` : `https://event.formapriv.fr/gen_json_questions.php?min_date=${dateMin}&center_id=${localCenter?.id}`
    }

    if ( selectedImportSource === 'demande de rappel' ) {
      console.log('Importation "Demandes de rappel"...')
      tempImportEvent = fetchedCenterEvents.find(event => event.name.toLowerCase().includes('demande de rappel'))?.id ?? process.env.REACT_APP_EVENT_GLOBAL_DEMANDE_RAPPEL ?? null
      tempImportSource = process.env.REACT_APP_LEAD_SOURCE_IMPORT_DEMANDE_RAPPEL ?? null
      tempSourceToFetch = isAdmin ? `https://event.formapriv.fr/gen_json_rappel.php?min_date=${dateMin}` : `https://event.formapriv.fr/gen_json_rappel.php?min_date=${dateMin}&center_id=${localCenter?.id}`
    }

    console.log('Importation de la source:', selectedImportSource, 'avec l\'événement:', tempImportEvent, 'et la source:', tempImportSource, 'à partir de:', tempSourceToFetch);

    if ( tempImportEvent === null || tempImportSource === null || tempSourceToFetch === null ) {
      notify('Impossible d\'effectuer l\'import.', { type: 'error' })
      setIsLoading(false)
      return
    }

    const importSource = tempImportSource
    const importEvent = tempImportEvent
    const sourceToFetch = tempSourceToFetch

    const importation = handleRefresh(selectedImportSource, sourceToFetch, importSource, importEvent)

    if ( ! importation ) {
      notify('Impossible d\'importer les contacts', { type: 'warning' });
      setIsLoading(false);
    }
  }

  const SimpleFormEditToolbar = () => (
    <Toolbar sx={{ background: 'none' }}>
      { isLoading
        ? <>
            <LoadingIcon />
            
            <Typography variant="body1" color="textSecondary" style={{ marginLeft: '10px' }} gutterBottom>
              {`${totalRecords} contacts à importer`}
            </Typography>

            <Typography variant="body1" color="textSecondary" style={{ marginLeft: '10px' }} gutterBottom>
              {`${nmbrOfImport} nouveaux contacts`}
            </Typography>
          </>
        :  <SaveButton
            label="Commencer l'import"
            icon={<UploadIcon />}
            type="button"
            style={{ margin: '0 auto' }}
            onClick={() => HandleImportBrochures()}
            />
      }
    </Toolbar>
  )

  return (
    <>
      <CommonDialog
        open={open}
        handleClose={() => setOpen(false)}
        title="Import de contacts externes"
        size="xs"
      >
        <SimpleForm
          toolbar={<SimpleFormEditToolbar />}
        >
          <CustomSelectInput
            label="Source de l'import"
            options={[
                { label: 'Demandes de brochures', value: 'demande de brochures' },
                { label: 'Demandes de rappel', value: 'demande de rappel' },
                { label: 'Contacts NOMAD', value: 'NOMAD' },
                { label: 'Contacts Diplomeo', value: 'diplomeo' },
                { label: 'Questions Parcoursup', value: 'q_parcoursup' },
              ]}
            onChange={(event, values) => setImportSourceFromSelect(values?.value)}
            minWidth="100%"
          />

          <DateInput
            id="dateMin"
            name="dateMin"
            source="dateMin"
            label="À partir de quelle date importer ?"
            onChange={(event) => setDateFromSelect(event.target.value)}
            variant="outlined"
            sx={{ mt: 4 }}
            fullWidth
          />
        </SimpleForm>
      </CommonDialog>

      <Button
        label="Récupérer de nouveaux contacts"
        onClick={() => setOpen(true)}
        color="success"
        variant={'contained'}
        disabled={isLoading ? true : false}
      />
    </>
  )
}
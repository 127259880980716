import { TextInput, useNotify, useRecordContext } from "react-admin"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export const TextInputCopy = ({ source, ...rest }) => {
  const record = useRecordContext()
  const notify = useNotify()
  
  const copyField = () => {
    if (record && record[source]) {
      navigator.clipboard.writeText(record[source])
      notify('ID copié dans le presse-papier', { type: 'success' })
    }
  };

  return record ? (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <TextInput variant="outlined" source={source} {...rest} />
      <ContentCopyIcon onClick={copyField} style={{ cursor: 'pointer', marginLeft: 8, marginTop: "-18px", fontSize: '18px' }} />
    </div>
  ) : null
}
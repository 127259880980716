import React, { useState } from 'react'
import { Button, useNotify } from 'react-admin'
import { supabaseClient } from '../supabase'

const SendMagicLinkButton = ({ email }) => {
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  const handleSendLink = async () => {
    setLoading(true)

    const { error } = await supabaseClient.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: 'https://forma-contacts.fr/leads/'
      }
    })
    

    setLoading(false)

    if (error) {
      console.error(`Error: ${error.message}`)
      notify('Impossible d\'envoyer le lien de connexion automatique', { type: 'error' })
      setLoading(false)
      return
    }

    notify('Lien de connexion automatique envoyé avec succès', { type: 'success' })
  }

  return (
    <Button
      label={ loading ? 'Envoi en cours...' : 'Envoyer un lien de connexion automatique' }
      variant="contained"
      color="secondary"
      onClick={handleSendLink}
      disabled={loading}
      sx={{ mt: .5, mb: 1 }}
      fullWidth
    />
  )
}

export default SendMagicLinkButton
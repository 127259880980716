import { useState, useEffect } from 'react'
import {
  downloadCSV,
  useDataProvider,
  CreateButton,
  ExportButton,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  SearchInput,
  TextField,
  TopToolbar,
  Button,
  useListContext,
  useGetIdentity,
  FunctionField,
  Link,
} from 'react-admin'
import { Typography, Card } from '@mui/material'
import jsonExport from 'jsonexport/dist'
import { List, Datagrid, IfCanAccess } from '@react-admin/ra-rbac'

import SchoolEdit from './SchoolEdit'
import SchoolCreate from './SchoolCreate'
import { SchoolListFilter } from './SchoolListFilter'
import { PostPagination } from '../common/Pagination'
import { ImportSchoolButton } from './SchoolImport'
import { classes } from '../common/classes'

import { getRole } from '../common/roles'
import SchoolListPubeur from './SchoolListPubeur'
import CancelIcon from '@mui/icons-material/Cancel'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import HandshakeIcon from '@mui/icons-material/Handshake'

import { supabaseClient } from '../supabase'

const SchoolList = props => {
  const dataProvider = useDataProvider()
  const { identity } = useGetIdentity()
  const localCenter = identity?.center
  const isAdmin = identity?.role?.grade === getRole('admin')
  const isPubeur = identity?.role?.grade === getRole('pubeur')

  const [pubeurSchoolsList, setPubeurSchoolsList] = useState([])

  useEffect(() => {
    if ( ! isPubeur ) return

    const getPubeurSchoolList = async () => {
      // get the list of events where the pubeur is assigned (event_pubeurs)
      const { data, error } = await supabaseClient
        .from('event_pubeurs')
        .select('event: events(schools_ids)') // get the schools_ids from the events
        .eq('pubeur_id', identity?.id)

      if ( error ) {
        console.error('error', error)
      }

      if ( ! data ) return

      let schoolsIds = data.map(event => event.event.schools_ids).flat()
      schoolsIds = schoolsIds.filter(id => id)

      // get all schools info
      const { data: schoolsData, error: schoolsError } = await supabaseClient
        .from('schools')
        .select('id, name, center_id')
        .in('id', schoolsIds)

      if ( schoolsError ) {
        console.error('error', schoolsError)
      }

      setPubeurSchoolsList(schoolsData)
    }

    getPubeurSchoolList()
  }, [isPubeur])

  const SchoolActions = props => {
    const { sort, filterValues, setSort, setFilters } = useListContext()

    return (
      <TopToolbar sx={{ 
        display: 'flex !important',
        justifyContent: 'right !important',
        alignItems: 'center !important',
        position: 'relative !important',
        width: '100%',
        paddingBottom: 0,
        minHeight: '60px',
        }}
      >
      { Object.keys(filterValues).length > 0 && (
        <Button color="error" label="filtres" onClick={() => setFilters({})}><CancelIcon /></Button>
      )}

      { ( sort.field !== 'created_at' || sort.order !== 'DESC' ) && (
        <Button color="error" label="triage" onClick={() => setSort({ field: 'created_at', order: 'DESC' })}><CancelIcon /></Button>
      )}
      
      <IfCanAccess action="create">
        <CreateButton icon={<AddCircleIcon />} label="Créer un lycée" />
      </IfCanAccess>

      <ImportSchoolButton {...props} />

      <IfCanAccess action="export">
        <ExportButton exporter={exporter} />
      </IfCanAccess>
    </TopToolbar>
  )}

  const exporter = async (schools) => {
    const schoolsForExport = await Promise.all(schools.map(async (school) => {
      const { id, name, address, center_id } = school

      const center = await dataProvider.getOne('centers', { id: center_id })
      const schoolLeadsCount = await dataProvider.getOne('school_leads_count', { id })

      return {
        id,
        name,
        postalCode: address.postalCode,
        centerName: center.data.name,
        seconde: schoolLeadsCount.data.class_id_1,
        premiere: schoolLeadsCount.data.class_id_2,
        terminale: schoolLeadsCount.data.class_id_3,
        licence: schoolLeadsCount.data.class_id_4,
        autre: schoolLeadsCount.data.class_id_0,
        total: schoolLeadsCount.data.total_leads,
      }
    }))

    jsonExport(schoolsForExport, {
      headers: ['id', 'name', 'postalCode', 'centerName', 'seconde', 'premiere', 'terminale', 'licence', 'autre', 'total'],
      rename: ['ID', 'Nom', 'Code postal', 'Centre', 'Seconde', 'Première', 'Terminale', 'Licence', 'Autre', 'Total'],
      rowDelimiter: ';',
    }, (err, csv) => {
      downloadCSV(csv, 'Export lycées')
    })
  }

  const schoolFilters = [
    <SearchInput placeholder="Nom" source="name@ilike" variant="outlined" alwaysOn resettable />,
    <SearchInput placeholder="Code postal" source="address->>postalCode@ilike" variant="outlined" style={{ width: '200px' }} alwaysOn resettable />,
  ]

  const SchoolList_ = () => (
    <>
      <Card variant="outlined" sx={{ width: '97%', p: 1.5, mb: 2, backgroundColor: '#f2f2f2' }}>
        <Typography color="textSecondary" variant="h6">
          Anciens pubeurs du lycée
        </Typography>
        
        <ReferenceManyField
          label={false}
          reference="profiles"
          target="from_school"
        >
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label="Contact"
              render={record => (
                <Link to={`#/leads/${record.id}`}>{`${record.first_name} ${record.last_name}`}</Link>
              )}
            />

            <TextField label="Téléphone" source="phone" />
            <TextField label="Adresse mail" source="email" />
          </Datagrid>
        </ReferenceManyField>
      </Card>

      <Card variant="outlined" sx={{ width: '97%', p: 1.5, mb: 2, backgroundColor: '#f2f2f2' }}>
        <Typography color="textSecondary" variant="h6">
          Anciens contacts inscrits du lycée
        </Typography>
        
        <ReferenceManyField
          label={false}
          reference="leads"
          target="school_id"
        >
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label="Contact"
              render={record => (
                <Link to={`#/leads/${record.id}`}>{`${record.first_name} ${record.last_name}`}</Link>
              )}
            />

            <TextField label="Téléphone" source="phone" />
            <TextField label="Adresse mail" source="email" />
          </Datagrid>
        </ReferenceManyField>
      </Card>
    </>
  )

  return (
    <>
      <List
        {...props}
        empty={false}
        actions={<SchoolActions {...props} />}
        filters={schoolFilters}
        pagination={<PostPagination />}
        perPage={50}
        aside={ isAdmin ? <SchoolListFilter /> : null }
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        { isPubeur ? (
          <SchoolListPubeur schools={pubeurSchoolsList} />
        ) : (
          <Datagrid rowClick="edit" expand={SchoolList_} expandSingle>
            <TextField label="Nom" source="name" />

            <FunctionField
              label=""
              render={record => (
                record.isPartner ? <HandshakeIcon sx={{ fontSize: '1.25rem' }} /> : null
              )}
            />
            
            { isAdmin &&
              <ReferenceField label="Centre" source="center_id" reference="centers" link={false}>
                <TextField source="name"/>
              </ReferenceField>
            }
            
            <TextField label="Code postal" source="address.postalCode" sortable={false} />

            <ReferenceField
              label="Évènements"
              source="id"
              reference="school_leads_count"
              link={false}
              sortable={false}
            >
              <FunctionField
                label="Évènements"
                render={record => {
                  if ( record?.eventinfo && record?.eventinfo.length > 0 ) {
                    return record?.eventinfo.map((event, key) => (
                      <div key={key} style={{ display: 'flex', alignItems: 'center', fontSize: '.65rem', minWidth: '140px' }}>
                        <span style={{ fontWeight: 'bold' }}>{event?.name} : </span>
                        <span style={{ marginLeft: '5px' }}>{event?.count}</span>
                      </div>
                    ))
                  }
                } }
              />
            </ReferenceField>

            { (classes[localCenter?.id]?.options ?? classes['default'].options).map((classe, key) => (
              <ReferenceField
                key={key}
                label={`${classe?.name}s`}
                source="id" 
                reference="school_leads_count"
                link={false}
                sortable={false}
              >
                <NumberField source={`class_id_${classe?.id}`} />
              </ReferenceField>
            )) }

            <ReferenceField
              label="Sans classe"
              source="id" 
              reference="school_leads_count"
              link={false}
              sortable={false}
            >
              <NumberField source={`class_id_none`} />
            </ReferenceField>
            
            <ReferenceField
              label="Total"
              source="id" 
              reference="school_leads_count"
              link={false}
              sortable={false}
            >
              <NumberField source={`total_leads`} />
            </ReferenceField>

            <ReferenceField
              label="Ratio Première"
              source="id" 
              reference="school_leads_count"
              link={false}
              sortable={false}
            >
              <FunctionField render={record => record.pre2 && <span><NumberField source="ratiopre" /> %</span> } />
            </ReferenceField>

            <ReferenceField
              label="Ratio Terminale"
              source="id" 
              reference="school_leads_count"
              link={false}
              sortable={false}
            >
              <FunctionField render={record => record.pre2 && <span><NumberField source="ratioter" /> %</span> } />
            </ReferenceField>

            <ReferenceField
              label="Première +2"
              source="id" 
              reference="school_leads_count"
              link={false}
              sortable={false}
            >
              <FunctionField render={record => record.pre2 && <span><NumberField source="pre2" /> %</span> } />
            </ReferenceField>

            <ReferenceField
              label="Terminale +2"
              source="id" 
              reference="school_leads_count"
              link={false}
              sortable={false}
            >
              <FunctionField render={record => record.ter2 && <span><NumberField source="ter2" /> %</span> } />
            </ReferenceField>
          </Datagrid>
        )}
      </List>
      
      {/* <SchoolShow /> */}
      <SchoolCreate />
      <SchoolEdit />
    </>
  )
}

export default SchoolList

import * as React from 'react'
import { CreateDialog } from '@react-admin/ra-form-layout'

import LeadForm from './LeadForm'

const LeadCreate = () => (
  <CreateDialog
    title="Ajouter un nouveau contact"
    maxWidth="md"
    sx={{
      '& .MuiDialogTitle-root': { padding: '14px 24px 14px 24px', backgroundColor: '#c5dedd', color: '#323232', fontWeight: 400 },
      '& .MuiCardContent-root': { padding: '0px 24px 24px 24px' }
    }}
  >
    <LeadForm action="create" />
  </CreateDialog>
)

export default LeadCreate;

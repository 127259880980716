import { supabaseClient } from '../supabase'
import baseDataProvider from '../supabase/baseDataProvider'

const profilesDataProvider = {
  ...baseDataProvider,
  getList: async (resource, params) => {
    if (params.filter.multi_search) {
      const localUserGrade = localStorage.getItem('localUserGrade')

      let query = supabaseClient
        .from('profiles_view')
        .select('*, role:roles(*)')
        .order('created_at', { ascending: false })
        .or(`first_name.ilike.%${params.filter.multi_search}%,last_name.ilike.%${params.filter.multi_search}%,email.ilike.%${params.filter.multi_search}%,phone.ilike.%${params.filter.multi_search}%`)

      if (localUserGrade && Number(localUserGrade) < 100) {
        query.url.href += `&and=(role_id.neq.597c594e-c095-4671-a2fa-bce91f2bf920)` // no vision on admins
      }

      const { data, error } = await query

      if (error) {
        console.error('Error fetching multisearch profiles:', error.message)
      }

      return { data: data ?? [], total: data?.length ?? 0 }
    }

    resource = 'profiles_view'

    // Cleaning spaces in search params
    const nbrOfFilters = Object.keys(params?.filter); // because params.filter has current_year_id by default

    if ( params.filter && nbrOfFilters?.length > 0 ) {
      let filters = params.filter

      function replaceSpacesWithUnderscores(obj) {
        if ( typeof obj !== 'object' ) return obj

        const result = {}

        for (const key in obj) {
          if ( typeof obj[key] === 'string') {
            const modifiedValue = obj[key].replace(/\s/g, '_')
            result[key] = modifiedValue
          } else {
            result[key] = obj[key]
          }
        }

        return result
      }

      const newFilters = replaceSpacesWithUnderscores(filters)

      params.filter = newFilters
    }

    return baseDataProvider.getList(resource, params);
  },
}

export const dataProvider = profilesDataProvider

import { React, useEffect, useState } from 'react'
import {
  ReferenceInput,
  AutocompleteInput,
  Button,
  TextInput,
  useGetIdentity,
  useNotify,
  SimpleForm,
  useRefresh,
  FormDataConsumer,
  SelectInput,
  TextField,
} from 'react-admin'
import { Autocomplete, Grid, Typography } from '@mui/material'

import { v4 as uuidv4 } from 'uuid'
import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import { CommonDialog } from '../common/Dialog'
import TasksActionTypes from './TasksActionTypes'
import { filterBlankSpaces } from '../common/customTextFilter'

import EditIcon from '@mui/icons-material/Edit'

const EditActionBranch = ({ refreshTree, fullTree, branch = '', parcoursId, setOpenEditActionDialog }) => {
  const notify = useNotify()
  const { identity } = useGetIdentity()

  const [editAction, setEditAction] = useState(true)
  const [actionInfo, setActionInfo] = useState(branch?.action ?? null)
  const [actionCondition, setActionCondition] = useState(branch?.condition ?? null)
  const [actionDescription, setActionDescription] = useState(branch?.detail ?? null)
  const [waitingTime, setWaitingTime] = useState(branch?.callback ?? null) // in hours
  const [followingActionInfo, setFollowingActionInfo] = useState(branch?.children[0]?.action ?? null)

  const findParentBranch = (tree, targetBranch) => {
    console.log('looking for parent branch', tree, targetBranch)
    
    if (!tree || typeof tree !== 'object') return null

    // not working
    for (const key in tree) {
      const branch = tree[key]

      console.log('branch', branch)

      if (branch.children?.some(child => child === targetBranch)) {
        return branch
      }

      const parent = findParentBranch(branch.children, targetBranch);
      if (parent) return parent
    }

    return null
  }

  const getActionChoices = () => {
    const parentBranch = findParentBranch(fullTree, branch)

    if ( ! parentBranch ) return [
      { id: 1, name: 'Si succès' },
      { id: -1, name: 'Si échec' },
      { id: 2, name: 'Seul choix possible' }
    ]

    // logic based on parent branch
    if (parentBranch.children.length === 1 && parentBranch.children[0].condition === null) return []

    if (parentBranch.children.length > 0) {
      return parentBranch.children[0].condition ? [{ id: -1, name: 'Si échec' }] : [{ id: 1, name: 'Si succès' }]
    }

    return [
      { id: 1, name: 'Si succès' },
      { id: -1, name: 'Si échec' },
      { id: 2, name: 'Seul choix possible' }
    ]
  }

  const findAndUpdateBranch = (tree, branchId, newBranch) => {
    // Check if the current node is the one we want to update
    if (tree.id === branchId) {
      return { ...newBranch, children: tree.children }  // Replace current node, preserve existing children
    }
  
    // Proceed to recursively update children if the current node is not the target
    const updatedChildren = tree.children.map(child => findAndUpdateBranch(child, branchId, newBranch))
  
    // Return the current tree node with updated children, only if there are changes
    if (updatedChildren.some((child, index) => child !== tree.children[index])) {
      return { ...tree, children: updatedChildren }
    }
  
    // If no child was updated, return the original tree node
    return tree
  }
  
  const saveNewAction = async () => {
    if ( ! actionInfo ) return { success: false }

    const conditon = actionCondition === 1 ? true : actionCondition === -1 ? false : null // succès = true, échec = false, sans rapport = null

    if ( branch && ! branch?.id ) branch.id = fullTree?.id

    const updatedAction = {
      id: branch?.id,
      parentId: branch?.parentId, // '' so that the key appears in the tree
      action: { 'id': actionInfo?.id, 'name': actionInfo?.name, 'choices': actionInfo?.choices?.options },
      callback: waitingTime,
      condition: conditon,
      result: null,
      detail: actionDescription,
      done: false,
      children: [],
    }

    if ( followingActionInfo ) {
      updatedAction.children.push({
        id: uuidv4(),
        parentId: updatedAction.id,
        action: { 'id': followingActionInfo?.id, 'name': followingActionInfo?.name, 'choices': actionInfo?.choices?.options },
        callback: null,
        condition: null,
        result: null,
        detail: null,
        done: false,
        children: [],
      })
    }

    const treeToUpdate = fullTree
    const currentBranch = branch.id
    const updatedFullTree = findAndUpdateBranch(treeToUpdate, currentBranch, updatedAction)

    // update parcours tree
    const { error: errorUpdateParcoursTree } = await supabaseClient
      .from('tasks_parcours')
      .update({ tree: updatedFullTree })
      .eq('id', parcoursId)
    
    if (errorUpdateParcoursTree) {
      console.error('Error updating parcours tree', errorUpdateParcoursTree)
      return { success: false }
    }

    return { success: true }
  }

  const handleSubmit = async (formData) => {
    const saveResult = await saveNewAction(formData)

    setActionCondition(null)
    setActionDescription(null)
    setActionInfo(null)
    setWaitingTime(null)
    setFollowingActionInfo(null)

    if ( ! saveResult.success ) {
      notify('Erreur lors de l\'enregistrement de la nouvelle action de tâche', { type: 'error' })
      return
    }
    
    notify('Action modifiée avec succès', { type: 'success' })
    refreshTree()
    setOpenEditActionDialog(false)
  }

  return (
    <SimpleForm
      toolbar={false}
      onSubmit={() => handleSubmit()}
    >
      { parcoursId && branch.parentId && ( branch.parentId !== fullTree.id ) && (
        <>
          <Typography variant="subtitle2">Condition actuelle: { branch?.condition ? 'Si succès' : !branch?.condition ? 'Si échec' : 'Seul choix possible' }</Typography>

          <AutocompleteInput
            label="Changer de condition"
            source="condition"
            variant="outlined"
            choices={getActionChoices()}
            onChange={(value, event) => setActionCondition(value ?? null) }
            fullWidth
          />
        </>
      )}

      <Typography variant="subtitle2">Action actuelle: {branch?.action?.name}</Typography>

      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>

          <ReferenceInput
            source="actionInfo"
            reference="tasks_actions"
            fullWidth
          >
            <AutocompleteInput
              optionText="name"
              variant="outlined"
              source="tasks_actions"
              label="Choisir une autre action"
              filterToQuery={searchText => filterBlankSpaces(searchText)}
              onChange={(value, event) => {
                setActionInfo(event)

                if ( ! branch || event?.id !== 'a113bba4-8949-4d19-975c-434a9c0539b3' ) {
                  setWaitingTime(null)
                  setFollowingActionInfo(null)
                  setEditAction(true)
                }

                if ( ! event ) setEditAction(false)
              }}
              value={actionInfo?.id}
              fullWidth
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={12} md={4}>
          { identity?.role?.grade === getRole('admin') && (
            <TasksActionTypes />
          )}
        </Grid>
      </Grid>

      { actionInfo?.id === 'a113bba4-8949-4d19-975c-434a9c0539b3' && (
        <>
          <Typography variant="subtitle2">Temps d'attente actuel: {branch?.callback ? `${branch?.callback}h` : 'Aucun'}</Typography>

          <SelectInput
            source="waitingTime"
            variant="outlined"
            label="Changer le temps avant de pouvoir continuer la tâche ?"
            choices={[
              { name: '12 heures', id: 12 },
              { name: '24 heures', id: 24 },
              { name: '48 heures', id: 48 },
              { name: '3 jours', id: 72 },
              { name: '5 jours', id: 120 },
              { name: '6 jours', id: 144 },
              { name: '1 semaine', id: 168 },
              { name: '2 semaines', id: 336 },
              { name: '1 mois', id: 720 },
              { name: '2 mois', id: 1440 },
              { name: '3 mois', id: 2160 },
              { name: '6 mois', id: 4320 },
              { name: '1 an', id: 8760 },
            ]}
            onChange={(event) => setWaitingTime(event?.target?.value)}
            resettable
            fullWidth
          />
        </>
      )}

      { actionInfo?.id === 'a113bba4-8949-4d19-975c-434a9c0539b3' && waitingTime && (
        <>
          <Typography variant="subtitle2">Action suivante : {branch?.children[0] ? branch?.children[0].action?.name : 'Aucune'}</Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <ReferenceInput
                source="followingActionInfo"
                reference="tasks_actions"
                fullWidth
              >
                <AutocompleteInput
                  optionText="name"
                  variant="outlined"
                  source="tasks_actions"
                  label="Changer l'action qui suivra"
                  filterToQuery={searchText => filterBlankSpaces(searchText)}
                  onChange={(value, event) => {
                    setFollowingActionInfo(event)

                    if ( ! event ) setEditAction(false)
                    else setEditAction(true)
                  }}
                  fullWidth
                />
              </ReferenceInput>
            </Grid>

            <Grid item xs={12} md={4}>
              { identity?.role?.grade === getRole('admin') && (
                <TasksActionTypes />
              )}
            </Grid>
          </Grid>
        </>
      )}

      { editAction && (
        <>
          <TextInput
            source="detail"
            label="Précisions pour le pubeur"
            variant="outlined"
            onChange={(event) => setActionDescription(event?.target?.value ?? null)}
            value={actionDescription}
            defaultValue={actionDescription}
            fullWidth
          />

          <Button
            label="Modifier"
            color="warning"
            variant="contained"
            startIcon={<EditIcon style={{ fontSize: '1.5rem' }} />}
            style={{ marginTop: '15px', width: '100%' }}
            type="submit"
          />
        </>
      )}
    </SimpleForm>
  )
}

const EditTaskAction = ({ parcoursId, fullTree, branch, refreshTree, openEditActionDialog, setOpenEditActionDialog }) => {
  return (
    <>
      <CommonDialog
        open={openEditActionDialog}
        handleClose={() => setOpenEditActionDialog(false)}
        title={`Modifier une action : ${branch?.action?.name}`}
        size="sm"
      >
        <EditActionBranch refreshTree={refreshTree} parcoursId={parcoursId} fullTree={fullTree} branch={branch} setOpenEditActionDialog={setOpenEditActionDialog} />
      </CommonDialog>
    </>
  )
}

export default EditTaskAction
import React, { useEffect, useState } from 'react'
import { useListContext, Loading, useRedirect } from 'react-admin'

import { styled } from '@mui/material/styles'

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1.5vw',
  padding: '1vw',
})

const Card = styled('div')(({ color, isVisible, isFullyVisible }) => ({
  backgroundColor: '#fefefe',
  borderBottom: `1vh solid ${color}`,
  borderRadius: '10px',
  padding: '1vw',
  width: 'calc(26% - 2vw)',
  boxSizing: 'border-box',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
  cursor: 'pointer',
  minHeight: '25vh',
  cursor: !isVisible && !isFullyVisible ? 'default' : 'pointer',
  '&:hover': ( isVisible || isFullyVisible ) && {
    boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
  },
}))

const EventsGridLayout = ({ children, ...props }) => {
  const { data, isLoading } = useListContext()
  const redirect = useRedirect()

  const handleCardClick = (eventId) => {
    redirect(`/events/${eventId}`)
  }

  return isLoading ? <Loading /> : (
    <Container {...props}>
      { data
        ?  data?.map(record => {
            const now = new Date().toISOString()
            const nowMinusOneDay = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
            const isVisible = now >= record.missionStartAff
            const isFullyVisible = nowMinusOneDay >= record.date

            return (
              <Card key={record.id} color={record.color} isVisible={isVisible} isFullyVisible={isFullyVisible} onClick={() => handleCardClick(record.id)}>
                {children(record)}
              </Card>
            )
          })
        : <p>Aucun événement disponible pour le moment.</p>
      }
    </Container>
  )
}

export default EventsGridLayout

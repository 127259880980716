const permissions = {
  // Admin
  100: [
    { action: ['list', 'show', 'create', 'edit', 'delete', 'export'], resource: '*' },
    { action: ['read', 'write'], resource: '*' },
  ],
  // Responsable
  50: [
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: ['create', 'edit', 'delete'], resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { type: 'deny', action: '*', resource: 'centers' },
    { action: ['list', 'show', 'create', 'edit', 'delete', 'export'], resource: '*' },
    { action: ['read', 'write'], resource: '*' },
  ],
  // Coordinateur
  20: [
    { type: 'deny', action: '*', resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { action: ['list', 'show', 'create', 'edit'], resource: '*' },
    { action: ['read', 'write'], resource: '*' },
  ],
  // Prof
  10: [
    { type: 'deny', action: '*', resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { action: ['list', 'show'], resource: '*' },
    { action: ['read', 'write'], resource: '*' },
  ],
  // Pubeur
  0: [
    { type: 'deny', action: 'delete', resource: 'leads' },
    { type: 'deny', action: 'delete', resource: 'parents' },
    { type: 'deny', action: '*', resource: 'types' },
    { type: 'deny', action: '*', resource: 'lead_years' },
    { type: 'deny', action: '*', resource: 'lead_sources' },
    { type: 'deny', action: '*', resource: 'qualifications' },
    { type: 'deny', action: '*', resource: 'tags' },
    { type: 'deny', action: '*', resource: 'tasks' },
    { type: 'deny', action: '*', resource: 'conversations' },
    { action: ['list', 'show', 'create', 'read'], resource: 'leads' },
    { action: ['list', 'show', 'create', 'read'], resource: 'parents' },
    { action: ['list', 'show', 'create', 'read'], resource: 'calendar' },
    { action: ['list', 'show', 'read'], resource: 'events' },
    { action: ['list', 'show', 'read'], resource: 'schools' },
    { action: ['list', 'show'], resource: 'parents' },
    { action: ['read', 'write'], resource: '*' },
  ],
}

export default permissions

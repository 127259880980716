import React, { useEffect, useState } from 'react'
import { Button, LoadingPage, useGetIdentity, useNotify, useRefresh } from 'react-admin'
import { Typography, Box, IconButton, Divider, Autocomplete, TextField } from '@mui/material'

import Tesseract from 'tesseract.js'
import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import { CommonDialog } from '../common/Dialog'

import CachedIcon from '@mui/icons-material/Cached'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import * as pdfjsLib from 'pdfjs-dist'
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.mjs`

function ExtractTextFromPdf () {
  const { identity } = useGetIdentity()
  const refresh = useRefresh()
  const notify = useNotify()

  const [open, setOpen] = useState(false)
  
  const [listOfProfiles, setListOfProfiles] = useState([])
  const [listOfLeads, setListOfLeads] = useState([])
  const [pubeurInfo, setPubeurInfo] = useState({ id: null, name: null })
  const [leadInfo, setLeadInfo] = useState({ id: null, name: null })

  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState(null)

  const [messages, setMessages] = useState([])
  const [originalMessages, setOriginalMessages] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  
  // image upload + preprocess + extract process
  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    const fileName = file.name.replace('.jpg', '').replace('.jpeg', '').replace('.png', '')
    setFileName(fileName)

    const reader = new FileReader()
  
    reader.onload = async (e) => {
      console.log('Loading image...');
      
      // save the original image data to get receiver messages readable
      const originalImageData = e.target.result

      // Preprocess the image before setting it to get sender messages readable
      const preprocessedImageData = await preprocessImage(e.target.result)
      
      extractTextUsingOCR(originalImageData, preprocessedImageData)
    }

    reader.readAsDataURL(file)
  }

  const preprocessImage = (imageData) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = imageData
    
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
    
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
    
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        const data = imageData.data

        // Apply contrast enhancement
        const contrast = 40 // Adjust as needed
        const factor = (259 * (contrast + 255)) / (255 * (259 - contrast))

        for (let i = 0; i < data.length; i += 4) {
          data[i] = factor * (data[i] - 128) + 128 // Red channel
          data[i + 1] = factor * (data[i + 1] - 128) + 128 // Green channel
          data[i + 2] = factor * (data[i + 2] - 128) + 128 // Blue channel
        }

        ctx.putImageData(imageData, 0, 0)

        // Dual-stage processing: Global threshold first, then selective inversion
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i]
          const g = data[i + 1]
          const b = data[i + 2]
          const brightness = (r + g + b) / 3

          // Stage 1: Apply a mild global threshold to ensure no text disappears
          const mildThreshold = brightness > 100 ? 255 : 0
          data[i] = data[i + 1] = data[i + 2] = mildThreshold

          // Stage 2: Apply selective inversion based on brightness
          if (brightness > 180) {
            // Invert colors for very light backgrounds (e.g., light blue)
            data[i] = 255 - r
            data[i + 1] = 255 - g
            data[i + 2] = 255 - b
          } else if (brightness < 100) {
            // No inversion needed for dark backgrounds, keep as is
            data[i] = r
            data[i + 1] = g
            data[i + 2] = b
          }
        }

        ctx.putImageData(imageData, 0, 0)
    
        resolve(canvas.toDataURL('image/jpeg'))
      }
    })
  }

  const extractTextUsingOCR = async (originalImage, processedImage) => {
    console.log('Using OCR to find text on the processed image and the original image...');

    function getMessages (words) {
      const Messages = []
      let currentMessage = {
        text: '',
        x: words[0].bbox.x0,
        y: words[0].bbox.y0,
        width: words[0].bbox.x1 - words[0].bbox.x0,
        height: words[0].bbox.y1 - words[0].bbox.y0
      }

      words.forEach((word, index) => {
        if (index === 0) {
          // Start with the first word
          currentMessage.text = word.text
        } else {
          // Check if the difference in y positions indicates a new message
          const yDifference = word.bbox.y0 - words[index - 1].bbox.y0
          if (yDifference > 70) {
            // Finish the current message and start a new one
            Messages.push(currentMessage)
            currentMessage = {
              text: word.text,
              x: word.bbox.x0,
              y: word.bbox.y0,
              width: word.bbox.x1 - word.bbox.x0,
              height: word.bbox.y1 - word.bbox.y0
            }
          } else {
            // Continue adding words to the current message
            currentMessage.text += ' ' + word.text
            // Adjust the bounding box if needed
            currentMessage.width = Math.max(currentMessage.width, word.bbox.x1 - currentMessage.x)
            currentMessage.height = Math.max(currentMessage.height, word.bbox.y1 - currentMessage.y)
          }
        }
      })

      // Push the last message
      Messages.push(currentMessage)

      return Messages
    }

    const resultSender = await Tesseract.recognize(processedImage, 'fra')
    const { words: wordsSender } = resultSender.data
    let messagesSender = getMessages(wordsSender)

    const resultReceiver = await Tesseract.recognize(originalImage, 'fra')
    const { words: wordsReceiver } = resultReceiver.data
    let messagesReceiver = getMessages(wordsReceiver)

    messagesSender = messagesSender.filter(message => 
      message.x >= 200 && 
      !['distribué', 'delivered'].includes(message.text.trim().toLowerCase())
    )
  
    messagesReceiver = messagesReceiver.filter(message => 
      message.x <= 200 && 
      !['distribué', 'delivered'].includes(message.text.trim().toLowerCase())
    )

    let allMessages = [...messagesSender, ...messagesReceiver]

    allMessages.sort((a, b) => a.y - b.y)

    const extractedData = [
      {
        pageNumber: 1,
        viewport: { width: resultSender.data.width, height: resultSender.data.height },
        textItems: allMessages.map(message => ({
          str: message.text,
          transform: [1, 0, 0, 1, message.x, message.y],
          width: message.width,
          height: message.height
        }))
      }
    ]

    parseConversation(extractedData)
    
    return false
  }

  // pdf upload + processing
  const handlePDFUpload = async (file) => {
    const fileName = file.name.replace('.pdf', '')
    setFileName(fileName)

    const extractedData = await extractTextAndPositionFromPDF(file)
    parseConversation(extractedData)
  }

  const extractTextAndPositionFromPDF = async (pdfFile) => {
    console.log('Extracting text from PDF file...');
    
    const arrayBuffer = await pdfFile.arrayBuffer()
    const pdf = await pdfjsLib.getDocument(arrayBuffer).promise
  
    const extractedData = []
  
    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber)
      const content = await page.getTextContent()
      const viewport = page.getViewport({ scale: 1 })
  
      const filteredTextItems = content.items
        .map(item => ({
          ...item,
          str: item.str.replace(/^Message\s*/, '').trim(),
        }))
        .filter(item => 
          item.str.length > 0 && // Filter out any empty strings
          !['Distribué', 'Delivered'].includes(item.str) // Exclude specific messages
        )
  
      const combinedTextItems = []
  
      filteredTextItems.forEach((item, index) => {
        const itemText = item.str
        const previousItem = combinedTextItems.length > 0 ? combinedTextItems[combinedTextItems.length - 1] : null
  
        if (itemText === '?' || itemText === '!') {
          if (previousItem) {
            previousItem.str += ' ' + itemText
          }
        } else if (itemText.startsWith(',') || itemText.startsWith('.') || itemText.startsWith(' ,') || itemText.startsWith(' .') || itemText.startsWith(':')) {
          if (previousItem) {
            previousItem.str += itemText
          }
        } else if (previousItem && previousItem.str.endsWith(',')) {
          previousItem.str += ' ' + itemText
        } else {
          combinedTextItems.push(item)
        }
      })
  
      extractedData.push({
        pageNumber,
        viewport,
        textItems: combinedTextItems.map(item => ({
          str: item.str,
          transform: item.transform,
          width: item.width,
          height: item.height,
        })),
      })
    }
  
    return extractedData
  }

  const parseConversation = (extractedData) => {
    console.log('Parsing conversation...')
    
    const classifiedMessages = classifyMessages(extractedData)
    const mergedEqualMessages = mergeEqualMessages(JSON.parse(JSON.stringify(classifiedMessages)))
    const mergedSeparatedMessages = mergeSeparatedMessages(JSON.parse(JSON.stringify(mergedEqualMessages)))
    const finalMessages = handleDateMessages(JSON.parse(JSON.stringify(mergedSeparatedMessages)))

    console.log('Conversation parsed!')

    setMessages(finalMessages)
    setOriginalMessages(finalMessages) // for resetting purposes
    setIsLoading(false)
  }

  // date parsing
  const padZero = (num) => num < 10 ? `0${num}` : `${num}`
  const padMilliseconds = (num) => num < 10 ? `00${num}` : num < 100 ? `0${num}` : `${num}`
  const formatDateString = (date) => {
    const year = date.getUTCFullYear()
    const month = padZero(date.getUTCMonth() + 1)
    const day = padZero(date.getUTCDate())
    const hours = padZero(date.getUTCHours())
    const minutes = padZero(date.getUTCMinutes())
    const seconds = padZero(date.getUTCSeconds())
    const milliseconds = padMilliseconds(date.getUTCMilliseconds())
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}+00`
  }

  const parseDateToTimestampz = (dateStr) => {
    const monthsMap = {
      // fr
      'janv': 0, 'févr': 1, 'mars': 2, 'avr': 3, 'mai': 4, 'juin': 5,
      'juil': 6, 'août': 7, 'sept': 8, 'oct': 9, 'nov': 10, 'déc': 11,
  
      'janvier': 0, 'février': 1, 'avril': 3, 'juillet': 6, 'août': 7,
      'septembre': 8, 'octobre': 9, 'novembre': 10, 'décembre': 11,
  
      // en
      'jan': 0, 'févr': 1, 'mars': 2, 'avr': 3, 'mai': 4, 'juin': 5,
      'juil': 6, 'août': 7, 'sept': 8, 'oct': 9, 'nov': 10, 'déc': 11,
  
      'january': 0, 'february': 1, 'april': 3, 'july': 6, 'august': 7,
      'september': 8, 'october': 9, 'november': 10, 'december': 11,
    }
    
    // Old date (ie: 5 mai 2023 à 18:09)
    if (/\d{1,2}\s+[\wéèàùûçÉÈÀÙÇ]{1,10}\s\d{4}\s(à|at)\s\d{1,2}\s?:\s?\d{2}/giu.test(dateStr)) {
      const [day, month, year, , time] = dateStr.split(' ')
      const monthIndex = monthsMap[month.replace('.', '')]

      const [hours, minutes] = time.replace(/\s/g, '').split(':')
      const date = new Date(Date.UTC(parseInt(year), monthIndex, parseInt(day), parseInt(hours), parseInt(minutes), 0, 0))
      const formattedDate = formatDateString(date)
      
      return formattedDate
    }

    // Recent date (ie: mar. 5 mai à 18:09 or sam. 9 déc. à 19:37)
    // Formating to YYYY-mm-dd HH:MM:SS.mmm
    if (/\b[\wéèàùçÉÈÀÙÇ]{1,10}\.?\s\d{1,2}\s[\wéèàùûçÉÈÀÙÇ]{1,10}\.?\s(à|at)\s\d{1,2}\s?:\s?\d{2}\b/giu.test(dateStr)) {
      const [ , day, month, , time] = dateStr.split(' ')
      const monthIndex = monthsMap[month.replace('.', '')]
      const currentYear = new Date().getFullYear()

      const [hours, minutes] = time.split(':')
      const date = new Date(Date.UTC(currentYear, monthIndex, parseInt(day), parseInt(hours), parseInt(minutes), 0, 0))
      const formattedDate = formatDateString(date)
      
      return formattedDate
    }

    // Today date (ie: Aujourd’hui 18:09)
    if (/(Aujourd(’|')hui|Today)\s\d{1,2}\s?:\s?\d{2}/giu.test(dateStr)) {
      const timePart = dateStr.match(/\d{1,2}\s?:\s?\d{2}/)[0].replace(/\s/g, '')
      const today = new Date()
      
      const [hours, minutes] = timePart.split(':')
      today.setUTCHours(parseInt(hours), parseInt(minutes), 0, 0)
      const formattedDate = formatDateString(today)
      
      return formattedDate
    }
    
    return null
  }
  
  const handleDateMessages = (mergedMessages) => {
    const messageOldDatesRegex = /\d{1,2}\s[\wéèàùûçÉÈÀÙÇ]{1,10}\s(\d{4}|)\sà\s\d{1,2}\s?:\s?\d{2}/giu
    const messageNormalDateRefex = /\b[\wéèàùçÉÈÀÙÇ]{1,10}\.?\s\d{1,2}\s[\wéèàùûçÉÈÀÙÇ]{1,10}\.?\sà\s\d{1,2}\s?:\s?\d{2}\b/giu
    const messageTodayDatesRegex = /(Aujourd(’|')hui|Today)\s\d{1,2}\s?:\s?\d{2}/giu
  
    let currentDate = null
  
    return mergedMessages.map((message, index) => {
      const messageIsAdate = messageOldDatesRegex.test(message.text) ||
                     messageNormalDateRefex.test(message.text) ||
                     messageTodayDatesRegex.test(message.text)
      
      // if 1st message is not a date, error
      /*if (index === 0 && !messageIsAdate) {
        setErrorMessage(`
          Attention ! La première ligne de la conversation doit être une date.
          Vous pouvez importer un nouveau fichier, ou supprimer des messages.
          En l'absence d'une date, les messages ne peuvent pas être importés dans le bon ordre.
        `)
      }*/
  
      if (messageIsAdate) {
        message.text = message.text.replace(/(\s|):(\s|)/, ':') // fix time display

        currentDate = parseDateToTimestampz(message.text)
        message.isDate = true
        message.createdAt = currentDate
      }
  
      if (!messageIsAdate && currentDate) {
        // Add 2 minutes to currentDate to order the messages properly
        const dateObj = new Date(currentDate)
        dateObj.setUTCMinutes(dateObj.getUTCMinutes() + 2)
      
        // Format the new date
        const newDateFormatted = formatDateString(dateObj)
        message.createdAt = newDateFormatted
        currentDate = newDateFormatted
      }

      if (!messageIsAdate && !currentDate) {
        // If the first message is not a date, add a default date
        // const defaultDate = formatDateString(new Date())
        // message.createdAt = defaultDate

        // message null which will be checked later to see if it matches another in the conversation
        // in case of a new conversation:
        // the date will be added to the messages regarding the first date found (-1 day and then +2 minutes)
        message.createdAt = null
      }
  
      return message
    })
  }  

  // message parsing/merging
  const mergeSeparatedMessages = (messages) => {
    if (messages.length === 0) return []

    const mergedSeparatedMessages = []
    let currentMessage = { ...messages[0] } // Start with a copy of the first message

    for (let i = 1; i < messages.length; i++) {
        const nextMessage = messages[i]
        
        // Check if the y difference is less than 20
        const yDifference = currentMessage.y - nextMessage.y
        if (yDifference > 0 && yDifference < 17) {
            currentMessage.text += ' ' + nextMessage.text
            currentMessage.y = nextMessage.y
        } else {
            mergedSeparatedMessages.push(currentMessage)
            currentMessage = { ...nextMessage }
        }
    }

    // push last message
    mergedSeparatedMessages.push(currentMessage)

    return mergedSeparatedMessages
  }
  
  const mergeEqualMessages = (messages) => {
    if (messages.length === 0) return []
  
    const mergedEqualMessages = []
    let currentMessage = { ...messages[0] } // Start with a copy of the first message
  
    for (let i = 1; i < messages.length; i++) {
      const nextMessage = messages[i]
      
      if (currentMessage.y === nextMessage.y) {
        currentMessage.text += ' ' + nextMessage.text
      } else {
        mergedEqualMessages.push(currentMessage)
        currentMessage = { ...nextMessage }
      }
    }
  
    // push last message
    mergedEqualMessages.push(currentMessage)

    return mergedEqualMessages
  }
  
  const classifyMessages = (extractedData) => {
    const classifiedMessages = []
    let messageNumber = 0

    extractedData.forEach(page => {
      page.textItems.forEach(item => {
        const [x, y] = [item.transform[4], item.transform[5]]
        const isSenderMessage = x > 200 // If text width position is over 200, it's a sender message

        classifiedMessages.push({
          text: item.str,
          y,
          isSenderMessage,
          isDate: false,
          order: messageNumber++,
        })
      })
    })

    return classifiedMessages
  }

  // file upload handler
  const handleFileUpload = async (event) => {
    const file = event.target.files[0]
    const fileType = file.type

    setMessages([])
    setOriginalMessages([])
    setErrorMessage(null)
    setIsLoading(true)

    if (fileType === 'image/jpeg' || fileType === 'image/png') {
      handleImageUpload(event)
      return
    }

    if (fileType === 'application/pdf') {
      handlePDFUpload(file)
      return
    }
  }

  const submitImport = async () => {
    if ( ! leadInfo.id ) {
      setFileName(null)
      setPubeurInfo({ id: null, name: null })
      setLeadInfo({ id: null, name: null })
      setMessages([])
      setOriginalMessages([])
      setOpen(false)
      setIsLoading(false)
      return
    }

    const getOrCreateConversation = async () => {
      console.log('Checking if conversation already exists...');
      
      // check if conversation already exists
      const { data, error } = await supabaseClient
        .from('conversations')
        .select()
        .eq('lead_id', leadInfo.id)
        .eq('pubeur_id', pubeurInfo.id)

      if (error) {
        console.error(error)
        return null
      }

      if (data.length > 0) {
        console.log('Conversation already exists');
        
        // if a conversation exists we need to check the messages to get the last message date
        const { data: lastMessage, error: lastMessageError } = await supabaseClient
          .from('conversation_messages')
          .select('created_at')
          .eq('conversation_id', data[0].id)
          .order('created_at', { ascending: false })
          .limit(1)

        if (lastMessageError) {
          console.error(lastMessageError)
          return null
        }

        let lastMessageDate = null
        if (lastMessage.length > 0) {
          lastMessageDate = lastMessage[0].created_at
        }

        return {
          id: data[0].id,
          lastMessageDate,
        }
      }

      // if not, initiate a new conversation
      console.log('Creating a new conversation...')
      
      const { data: newConversation, error: newConversationError } = await supabaseClient
        .from('conversations')
        .insert({ lead_id: leadInfo.id, pubeur_id: pubeurInfo.id, current_year_id: process.env.REACT_APP_CURRENT_YEAR_ID })
        .select()

      if (newConversationError) {
        console.error(newConversationError)
        return null
      }

      return {
        id: newConversation[0].id,
        lastMessageDate: null,
      }
    }

    const conversationInfo = await getOrCreateConversation()

    if ( ! conversationInfo.id ) return

    // make sure all messages have a createdAt date, going backwards
    for (let i = messages.length - 1; i >= 0; i--) {
      const message = messages[i]
      const { text, createdAt } = message
    
      // Skip messages that already have a createdAt date
      if ( ! createdAt)  {
        let nextMessageIndex = i + 1
        let nextMessage = messages[nextMessageIndex]

        while (nextMessage && !nextMessage.createdAt && !nextMessage.isDate) {
          nextMessageIndex++
          nextMessage = messages[nextMessageIndex]
        }
        
        if (nextMessage) {
          const dateObj = new Date(nextMessage.createdAt)
          
          // Adjust the current message's date to be 2 minutes earlier than the next one
          dateObj.setUTCMinutes(dateObj.getUTCMinutes() - 2)
          message.createdAt = formatDateString(dateObj)
        } else {
          message.createdAt = formatDateString(new Date())
        }
    
        console.log(`Setting date for message "${text}" to ${message.createdAt}`)
      }
    }

    // then add messages to the conversation
    for (const message of messages) {
      const { isSenderMessage, isDate, text, createdAt } = message
      const senderType = isSenderMessage ? 'pubeur' : 'lead'
      const senderId = isSenderMessage ? identity?.id : leadInfo.id

      if ( conversationInfo.lastMessageDate && createdAt < conversationInfo.lastMessageDate ) {
        console.log(`Skipping message ${text}`)
        continue
      }

      const messagesValues = {
        sender_id: senderId,
        sender_type: senderType,
        text,
        paid: !isDate && text.length > 20,
        isDate,
        created_at: createdAt,
      }

      const insertNewMessage = async () => {
        console.log(`Inserting message ${text}`);
        
        const { error } = await supabaseClient
          .from('conversation_messages')
          .insert({...messagesValues, conversation_id: conversationInfo.id})
        
        if (error) {
          console.error(error)
          return null
        }
      }

      // insertNewMessage()
    }

    notify('Messages importés avec succès', { type: 'success' })
    setFileName(null)
    setPubeurInfo({ id: null, name: null })
    setLeadInfo({ id: null, name: null })
    setMessages([])
    setOriginalMessages([])
    setOpen(false)
    setIsLoading(false)
    refresh()
  }

  // messages operations
  const resetToOriginalMessages = () => {
    setMessages(originalMessages)
  }

  const deleteCurrentMessage = (index) => {
    const newMessages = [...messages];
  
    // Check if the previous message is a date and remove it as well
    if (index > 0 && newMessages[index - 1].isDate && newMessages[index + 1].isDate) {
      newMessages.splice(index - 1, 1)
      index-- // Adjust the index to account for the removed date
    }
  
    // Remove the current message
    newMessages.splice(index, 1)
  
    setMessages(newMessages)
  }

  // hooks to get pubeurs and leads
  useEffect(() => {
    if ( ! pubeurInfo.id ) return

    const fetchLeads = async () => {
      const { data, error } = await supabaseClient
        .from('leads')
        .select()
        .eq('assigned_pubeur', pubeurInfo.id)
      
      if (error) {
        console.error(error)
        return
      }

      const leads = data.map(lead => ({ id: lead.id, name: lead.first_name + ' ' + lead.last_name }))

      setListOfLeads(leads)
    }

    fetchLeads()
  }, [pubeurInfo])

  useEffect(() => {
    const fetchProfiles = async () => {
      if ( identity?.role?.grade === getRole('admin') ) {
        const { data, error } = await supabaseClient
          .from('profiles')
          .select()
        
        if (error) {
          console.error(error)
          return
        }

        const profiles = data.map(profile => ({ id: profile.id, name: profile.first_name + ' ' + profile.last_name }))
        setListOfProfiles(profiles)
        return
      }

      const { data, error } = await supabaseClient
        .from('profiles')
        .select()
        .eq('center_id', identity?.center?.id)
      
      if (error) {
        console.error(error)
        return
      }

      const profiles = data.map(profile => ({ id: profile.id, name: profile.first_name + ' ' + profile.last_name }))

      setListOfProfiles(profiles)
    }

    fetchProfiles()
  }, [])

  return (
    <>
      <CommonDialog
        title="Importer une conversation"
        open={open}
        handleClose={() => {
          setMessages([])
          setFileName(null)
          setPubeurInfo({ id: null, name: null })
          setLeadInfo({ id: null, name: null })
          setFileName(null)
          setMessages([])
          setOpen(false)
          setIsLoading(false)
        }}
        size="sm"
        icon={<OpenInNewIcon />}
      >
        { isLoading ? (
          <LoadingPage />
        ) : (
          <>
            { messages.length === 0 && (
              <>
                <h3>Importer une conversation SMS</h3>

                <Autocomplete
                  disablePortal
                  options={listOfProfiles}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => setPubeurInfo({ id: value?.id ?? null, name: value?.name ?? null })}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => {
                    return (
                      <TextField {...params} label="Pubeur" variant="outlined" />
                    )
                  }}
                />

                { pubeurInfo?.id && (
                  <Autocomplete
                    disablePortal
                    options={listOfLeads}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => setLeadInfo({ id: value?.id ?? null, name: value?.name ?? null })}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => {
                      return (
                        <TextField {...params} label="Contact étudiant" variant="outlined" />
                      )
                    }}
                  />
                )}
              </>
            )}

            { messages.length === 0 && pubeurInfo.id && leadInfo.id && (
              <>
                <h3>Importer un fichier (capture d'écran ou conversation PDF)</h3>

                <input
                  type="file"
                  onChange={handleFileUpload}
                  style={{
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    width: '90%',
                    boxSizing: 'border-box',
                    marginTop: '20px'
                  }}
                />
              </>
            )}
          </>
        )}
        
        {messages.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" gutterBottom>
              Conversation avec { leadInfo?.name ?? fileName ?? 'un contact' }
              <IconButton
                size="small"
                sx={{
                  ml: 2,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={() => resetToOriginalMessages()}
              >
                <CachedIcon />
              </IconButton>
            </Typography>

            { errorMessage && (
              <Typography variant="body1" color="error" gutterBottom>
                { errorMessage }
              </Typography>
            )}

            <Divider orientation="horizontal" aria-hidden="true" sx={{ mt: 1, mb: 1 }} />
            
            {messages.map((message, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent={
                  message.isDate
                    ? 'center'
                    : message.isSenderMessage
                    ? 'flex-end'
                    : 'flex-start'
                }
                sx={{
                  fontWeight: message.isDate ? 'bold' : 'normal',
                  fontSize: message.isDate ? '.75rem' : '.95rem',
                }}
              >
                {!message.isSenderMessage && !message.isDate && (
                  <IconButton
                    size="small"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    onClick={() => deleteCurrentMessage(index)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )}

                <Box
                  component="div"
                  style={{
                    textAlign: message.isDate
                      ? 'center'
                      : message.isSenderMessage
                      ? 'right'
                      : 'left',
                    backgroundColor: message.isDate
                      ? 'transparent'
                      : message.isSenderMessage
                      ? '#56a39f'
                      : '#dcdcdc',
                    color: message.isDate ? '#999' : message.isSenderMessage ? 'white' : 'black',
                    padding: '10px',
                    margin: message.isDate ? '15px 5px 5px 5px' : '5px',
                    borderRadius: '10px',
                    maxWidth: message.isDate ? '100%' : '60%',
                  }}
                >
                  {message.text}
                </Box>

                {message.isSenderMessage && !message.isDate && (
                  <IconButton
                    size="small"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    onClick={() => {
                      console.log('Icon clicked');
                      deleteCurrentMessage(index);
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )}
              </Box>
            ))}

            <Button
              label="Importer la conversation"
              variant="contained"
              color="primary"
              onClick={() => submitImport()}
              sx={{ mt: 4, mb: 4, p: 1.5 }}
              fullWidth
            />

            <Divider orientation="horizontal" aria-hidden="true" sx={{ mb: 4 }} />

            <Typography variant="body2" color="textSecondary">
              Importer un autre fichier
            </Typography>
            
            { isLoading ? (
              <LoadingPage />
            ) : (
              <input
                type="file"
                onChange={handleFileUpload}
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  width: '90%',
                  boxSizing: 'border-box',
                  marginTop: '20px'
                }}
              />
            )}
          </div>
        )}
      </CommonDialog>

      <Button
        startIcon={<OpenInNewIcon />}
        label="Importer une conversation"
        variant="outlined"
        color="primary"
        onClick={() => setOpen(true)}
      />
    </>
  )
}

export default ExtractTextFromPdf
export const filterBlankSpaces = (searchText) => {
  const sanitizedSearchText = searchText.replace(/\s/g, '_')
  
  return { 'name@ilike': sanitizedSearchText }
}

export const filterProfileBlankSpaces = (searchText) => {
  const sanitizedSearchText = searchText.replace(/\s/g, '_')

  return {
    '@or': {
      'first_name@ilike': sanitizedSearchText,
      'last_name@ilike': sanitizedSearchText,
    },
    '@and': { 'enabled': true }
  } 
}

export const filterEmailSpaces = (searchText) => {
  const sanitizedSearchText = searchText.replace(/\s/g, '_')
  
  return { 'email@ilike': sanitizedSearchText }
}
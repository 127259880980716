import { ImportButton } from "react-admin-import-csv";
import { formatPhoneNumber, getValidEmail, toCamelCase, toLowerCase } from '../common/Formating';

export const ImportSchoolButton = props => {
    const config = {
        logging: true,
        disableImportOverwrite: true,
        disableCreateMany: true,
        disableUpdateMany: true,
        transformRows: csvRows => {
          const results = [];
          csvRows.map((row, index) => {
            results[index] = {
              name: toCamelCase(row.NOM),
              phone: formatPhoneNumber(row.TEL),
              email: getValidEmail(row.MAIL),
              distance_from_center: row['DISTANCE KM'],
              center_id: row.CENTRE,
              address: {
                street: toLowerCase(row.ADRESSE),
                postalCode: row['CODE POSTAL'],
                city: toLowerCase(row.VILLE),
              },
            };
          });

          return results;
        },
        parseConfig: {
          dynamicTyping: true,
          delimiter: "",
          skipEmptyLines: true,
        },
      };

    return <ImportButton {...props} {...config} />;
};

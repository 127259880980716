import React, { useEffect, useState } from 'react'
import {
  required,
  useCreate,
  useDelete,
  useNotify,
  useRefresh,
  useGetIdentity,
  useUpdate,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  DateInput,
  Labeled,
  ReferenceInput,
  AutocompleteInput,
  useRedirect,
} from "react-admin"
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'

import dataProvider from '../supabase/dataProvider'

import sendMail from '../common/SendMail'
import toTimeZone from '../common/toTimeZone'
import { CommonDialog } from '../common/Dialog'
import { btnAction } from '../Design/CustomStyles'
import { ContactLogs } from './InboundContactUtils'
import createNotification from '../common/createNotification'

import RefreshIcon from '@mui/icons-material/Refresh'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export const ActionDialog = ({ record, open, setOpen, refresh, ...props }) => {
  const [update] = useUpdate()
  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()
  // const refresh = useRefresh()
  const {identity} = useGetIdentity()

  const [originType, setOriginType] = useState(null)
  const [actionType, setActionType] = useState(null)
  const [actionResult, setActionResult] = useState(null)
  const [interet, setInterest] = useState(null)
  const [callback, setCallback] = useState(null)

  const [status, setStatus] = useState(['new', 'processing'].includes(record?.status) ? 'processing' : record.status)
  const [comment, setComment] = useState('')

  const CustomToolbar = () => (
    <Toolbar sx={{ background: 'none' }}>
      <SaveButton fullWidth label="Valider" />
    </Toolbar>
  )

  const handleSubmitAction = async () => {
    actionResult === 'voicemail' || actionResult === 'no_response'
      ? setStatus('processing')
      : setStatus('done')

      console.log('data', {
        status: actionResult === 'voicemail' || actionResult === 'no_response'
        ? 'processing'
        : 'done',
        comment: comment,
        last_action: { originType, actionType, actionResult, interet },
        updated_at: toTimeZone(new Date(), 'Europe/Paris'),
        callback: callback,
      });

    try {
      await update('inbound_contacts', {
        id: record.id,
        data: {
          status: actionResult === 'voicemail' || actionResult === 'no_response'
          ? 'processing'
          : 'done',
          comment: comment,
          last_action: { originType, actionType, actionResult, interet },
          updated_at: toTimeZone(new Date(), 'Europe/Paris'),
          callback: callback,
        },
        previousData: record
      })

      await create('inbound_contact_logs', {
        data: {
          inbound_contact_id: record.id,
          action: { originType, actionType, actionResult, interet },
          new_values: { ...record, comment, status },
          user_id: identity.id,
          callback: callback,
          lead_event_id: record.lead_event_id,
        }
      })

      const interetData = {
        'stop_rgpd': { stop_rgpd: true },
        'interested': { interested: true },
        'not_interested': { not_interested: true }
      }
      
      if (interetData.hasOwnProperty(interet)) {
        await update('leads', {
          id: record.lead_id,
          data: interetData[interet],
          previousData: record
        })
      }

      if (comment) {
        await create('lead_comments', {
          data: {
            lead_id: record.lead_id,
            comment: `Action contact entrant: ${comment}`,
            profile_id: identity?.id,
            event_id: null
          }
        })
      }

      if ( callback ) {
        const {data: leadRecord, error: leadError} = await dataProvider.getOne('leads', { id: record.lead_id })

        if ( leadError ) throw leadError

        if ( record.lead_id && leadError ) {
          console.log('Erreur, ce lead n\'existe plus :', leadError)

          dataProvider.delete('inbound_contacts', {
            id: record.id,
            previousData: record
          }).then(response => console.log(response))

          notify('Le lead n\'existe plus, la fiche contact a été supprimée.', { type: 'warning'})
          redirect('/inbound_contacts')
        }

        createNotification({
          profile_id: identity.id,
          type: 'callback',
          date: callback,
          title: `Vous avez un rappel à effectuer.`,
          message: `Un RDV a été programmé pour rappeler ${leadRecord.first_name} ${leadRecord.last_name} le ${callback}. Cliquez pour vous rendre sur sa fiche.`,
          url: `/inbound_contacts/${record.id}`,
        })

        const type = actionType === 'call' ? 'appel' : actionType === 'sms' ? 'SMS' : 'email'
        const messageHTML = `
        <h3>Rappel de rendez-vous FormaContacts</h3>

        <h4>Un recontact par ${type} a été programmé pour aujourd'hui.</h4>

        <p>
          <b>Prénom</b>: ${leadRecord?.first_name ?? 'Non renseigné'}<br />
          <b>Nom</b>: ${leadRecord?.last_name ?? 'Non renseigné'}<br />
          <b>Email</b>: ${leadRecord?.email ?? 'Non renseigné'}<br />
          <b>Téléphone</b>: ${leadRecord?.phone ?? 'Non renseigné'}
        </p>

        <p>
          <a href="https://forma-contacts.fr/inbound_contacts/${record.id}">Cliquez ici pour consulter la fiche contact.</a>
        </p>

        <p>
          <i>Ceci est un message automatique, merci de ne pas y répondre.</i>
        </p>
       `

        const messageTEXT = `RAPPEL DE RDV FORMACONTACTS\n\nUn ${type} a été programmé pour aujourd'hui.\n\nPrénom: ${leadRecord.first_name}\nNom: ${leadRecord.last_name}\nEmail: ${leadRecord.email}\nTéléphone: ${leadRecord.phone}\n\nCliquez ici pour consulter la fiche contact: https://forma-contacts.fr/inbound_contacts/${record.id}\n\nCeci est un message automatique, merci de ne pas y répondre.`
        
        sendMail ({
          identity: identity,
          subject: `Rendez-vous programmé avec un contact aujourd'hui`,
          messageHTML: messageHTML,
          messageTEXT: messageTEXT,
          date: callback,
          sendNow: false
        })
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error)
    } finally {
      setComment(null)
      setOriginType(null)
      setActionType(null)
      setCallback(null)
      setInterest(null)
      setActionResult(null)

      notify('Action enregistrée', { type: 'success'})
      setOpen(false)
      refresh()
      // redirect('/inbound_contacts')
    }
  }

  return record && record.status !== 'done'
    ? <CommonDialog
        open={open}
        handleClose={() => setOpen(false)}
        displayDialogActions={false}
        title="Effectuer une action"
        size="sm"
      >
        <SimpleForm
          {...props}
          toolbar={<CustomToolbar />}
          onSubmit={handleSubmitAction}
        >
          <RadioButtonGroupInput
            label=""
            source="originType"
            validate={required()}
            onChange={e => {
              setActionType(null)
              setOriginType(e.target.value)
            }}
            choices={[
              { id: 'outbound', name: 'Je contacte' },
              { id: 'inbound', name: 'On me recontacte' }
            ]}
          />

          { originType && (
            <RadioButtonGroupInput
              label="Type d'action"
              source="actionType"
              validate={required()}
              onChange={e => {
                setActionResult(null ?? '')
                setActionType(e.target.value)
              }}
              choices={[
                { id: 'call', name: 'Appel' },
                { id: 'sms', name: 'SMS' },
                { id: 'email', name: 'Email' },
              ]}
              sx={{ margin: '0px' }}
            />
          )}

          { actionType && actionType === 'call' && (
            <RadioButtonGroupInput
              label="Résultat de l'appel"
              source="actionResult"
              onChange={e => setActionResult(e.target.value)}
              validate={required()}
              choices={[
                { id: 'voicemail', name: 'Répondeur / doit rappeler' },
                { id: 'called', name: 'Appel traité' },
              ]}
              sx={{ margin: '0px' }}
            />
          )}

          {actionType && actionType === 'sms' && (
            <RadioButtonGroupInput
              label="Résultat du SMS"
              source="actionResult"
              onChange={e => setActionResult(e.target.value)}
              validate={required()}
              choices={[
                { id: 'no_response', name: 'Pas de réponse / Doit réécrire' },
                { id: 'response_received', name: 'Réponse reçue' },
              ]}
              sx={{ margin: '0px' }}
            />
          )}

          {actionType && actionType === 'email' && (
            <RadioButtonGroupInput
              label="Résultat du mail"
              source="actionResult"
              onChange={e => setActionResult(e.target.value)}
              validate={required()}
              choices={[
                { id: 'no_response', name: 'Pas de réponse / Doit réécrire' },
                { id: 'response_received', name: 'Réponse reçue' },
              ]}
              sx={{ margin: '0px' }}
            />
          )}

          { actionResult && (
            <>
              { ( actionResult === 'no_response' || actionResult === 'voicemail' ) && (
                <Labeled label="Programmer un rappel, renvoi sms, ou mail" fullWidth>
                  <DateInput
                    label=""
                    source="callback"
                    variant="outlined"
                    onChange={e => setCallback(e.target.value)}
                    fullWidth
                  />
                </Labeled>
              )}
            
              <TextInput
                label="Ajouter un commentaire (optionnel)"
                source="new_comment"
                variant="outlined"
                multiline
                sx={{width: '100%'}}
                onChange={e => setComment(e.target.value)}
              />
            </>
          )}
        </SimpleForm>
      </CommonDialog>
    : null
}

export const ActionMenu = ({ record, refresh, openInfo }) => {
  const [open, setOpen] = useState(openInfo)
  
  return (
  <>
    <Card elevation={3} style={{ width: '100%' }}>
      <CardHeader 
        title={
          <>
            Historique des actions

            <IconButton onClick={refresh}>
              <RefreshIcon />
            </IconButton>
          </>
        }
        action={
          record && record.status !== 'done'
            ?
              <Button style={btnAction} onClick={() => setOpen(true)}>
                <AddCircleOutlineIcon style={{ marginRight: '10px'}} /> Effectuer une action
              </Button>
            :
              <p>
                <i>Aucune nouvelle action n'est possible pour un contact déjà traité.</i>
              </p>
        }
      />

      <Divider />

      <CardContent>
        <ContactLogs record={record} />
      </CardContent>
    </Card>

    <ActionDialog record={record} open={open} refresh={refresh} setOpen={setOpen} />
  </>
)}
import { useState } from 'react'
import { Button, Datagrid, DateInput, FunctionField, Link, List, ReferenceField, SimpleForm, TextField, TextInput, TimeInput, Toolbar, TopToolbar, useEditContext, useGetIdentity, useRecordContext, useRefresh } from 'react-admin'
import { DateTimeInput } from '@react-admin/ra-form-layout'
import { Grid, Stack, Typography } from '@mui/material'

import { supabaseClient } from '../supabase'
import { CommonDialog } from '../common/Dialog'
import sendMail from '../common/SendMail'

const AddNewRDV = ({ empty = false }) => {
  const { identity } = useGetIdentity()
  const refresh = useRefresh()
  const [openRdvDialog, setOpenRdvDialog] = useState(false)
  const [rdvTitle, setRdvTitle] = useState(null)
  const [dateRDV, setDateRDV] = useState(null)
  const [timeStart, setTimeStart] = useState(null)
  const [timeEnd, setTimeEnd] = useState(null)
  const record = useRecordContext()

  const handleLeadRDV = () => {
    const createRDV = async () => {
      // Extract the date part from dateRDV
      let datePart = new Date(dateRDV).toISOString().split('T')[0]; // 'YYYY-MM-DD'

      // Extract the time parts from timeStart and timeEnd
      let timeStartPart = new Date(timeStart).toISOString().split('T')[1]; // 'HH:mm:ss.sssZ'
      let timeEndPart = new Date(timeEnd).toISOString().split('T')[1];     // 'HH:mm:ss.sssZ'

      // Combine the date with the time parts
      let dateStart = new Date(`${datePart}T${timeStartPart}`);
      let dateEnd = new Date(`${datePart}T${timeEndPart}`);

      dateStart.setHours(dateStart.getHours() - 2);
      dateEnd.setHours(dateEnd.getHours() - 2)

      let dateToSendMail = new Date(dateStart)
      dateToSendMail.setHours(dateToSendMail.getHours() - 2)

      const { data, error } = await supabaseClient
        .from('calendar')
        .insert([
          { title: rdvTitle, start: dateStart, end: dateEnd, profile_id: identity?.id, center_id: record.center_id }
        ])
        .select()

      if (error) {
        console.error('Error creating RDV', error)
        return
      }

      if ( ! data || data.length === 0 ) return

      const { data: dataRDV , error: errorRDV } = await supabaseClient
        .from('lead_rdv')
        .upsert([
          { lead_id: record?.id, calendar_event_id: data[0].id }
        ])
        .select()

      if (errorRDV) {
        console.error('Error creating RDV', errorRDV)
        return
      }

      const { error: errorLeadRDV } = await supabaseClient
        .from('calendar')
        .update([
          { lead_rdv: dataRDV[0]?.id }
        ])
        .eq('id', data[0].id)

      if (errorLeadRDV) {
        console.error('Error updating lead RDV', errorLeadRDV)
        return
      }

      // create email notification
      // format date to display cleanly
      const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }
      const formattedDate = dateStart.toLocaleDateString('fr-FR', options)

      const messageHTML = `
        <h3>Rappel de rendez-vous FormaContacts</h3>

        <h4>Vous avez un rendez-vous programmé pour aujourd'hui avec ${record?.first_name} ${record?.last_name} le ${formattedDate}.</h4>

        <p>
          <a href="https://forma-contacts.fr/leads/${record?.id}">Cliquez ici pour consulter la fiche contact.</a>
        </p>

        <p>
          <i>Ceci est un message automatique, merci de ne pas y répondre.</i>
        </p>
      `

      const messageTEXT = `RAPPEL DE RDV FORMACONTACTS\n\nUn rendez-vous a été programmé aujourd'hui avec ${record?.first_name} ${record?.last_name} le ${formattedDate}\n\nCliquez ici pour consulter la fiche contact: https://forma-contacts.fr/leads/${dataRDV[0]?.id}\n\nCeci est un message automatique, merci de ne pas y répondre.`

      sendMail ({
        identity: identity,
        subject: `Rendez-vous programmé avec un contact aujourd'hui`,
        messageHTML: messageHTML,
        messageTEXT: messageTEXT,
        date: dateToSendMail,
        sendNow: false
      })

      refresh()
      setTimeout(() => setOpenRdvDialog(false), 2000)
    }

    createRDV()
  }

  const SimpleFormEditToolbar = () => (
    <Toolbar sx={{ background: 'none' }}>
      <Button
        label="Programmer un RDV"
        variant="contained"
        size="large"
        style={{ margin: '0 auto' }}
        onClick={() => handleLeadRDV()}
        disabled={!rdvTitle || !dateRDV || !timeStart || !timeEnd}
      />
    </Toolbar>
  )

  return (
    <>
      <CommonDialog
        open={openRdvDialog}
        setOpen={setOpenRdvDialog}
        handleClose={() => setOpenRdvDialog(false)}
        title="Programmer un RDV"
        size="sm"
      >
        <SimpleForm toolbar={<SimpleFormEditToolbar />}>
          <TextInput source="title" label="Titre du RDV" onChange={(event) => setRdvTitle(event?.target?.value)} variant="outlined" autoFocus fullWidth />
          
          <DateInput
            source="daterdv"
            label="Date du RDV"
            onChange={(e) => setDateRDV(e.target.valueAsDate)}
            variant="outlined"
            fullWidth
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TimeInput
                source="timeStart"
                label="Début du RDV"
                onChange={e => setTimeStart(e.target.valueAsDate)}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <TimeInput
                source="timeEnd"
                label="Fin du RDV"
                onChange={e => setTimeEnd(e.target.valueAsDate)}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </CommonDialog>
    
      { empty
        ?
          <Stack spacing={4} direction="column" alignItems="center" justifyContent="center" sx={{ width: '95%', mt: 5 }}>
            <Typography variant="h5">Rendez-vous</Typography>

            <Typography variant="body1">
              Vous n'avez pas encore de RDV pour ce lead
            </Typography>

            <Button
              label="Programmer un RDV"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setOpenRdvDialog(true)}
            />
        </Stack>
      : 
        <TopToolbar>
          <Stack spacing={1} direction="row" alignItems="center" justifyContent="right">
            <Button
              label="Ajouter un nouveau RDV"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpenRdvDialog(true)}
              sx={{ mr: 5 }}
            />
          </Stack>
      </TopToolbar>
      }
    </>
  )
}

const LeadRDV = () => {
  const record = useRecordContext()

  return (
    <List
      disableSyncWithLocation
      resource="lead_rdv"
      perPage={1000}
      actions={<AddNewRDV />}
      exporter={false}
      pagination={false}
      empty={<AddNewRDV empty={true} />  }
      filter={{ lead_id: record?.id }}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          label="Titre"
          source="calendar_event_id"
          reference="calendar"
        >
          <FunctionField render={record => 
            <Link to={`https://forma-contacts.fr/calendar/`} target="_blank">
              <TextField source="title" />
            </Link>
          } />
        </ReferenceField>

        <ReferenceField
          label="Début"
          source="calendar_event_id"
          reference="calendar"
        >
          <FunctionField render={record => {
            if (!record || !record.start) return null

            const datePart = record.start.split('T')[0]
            const timePart = record.start.split('T')[1]?.replace(':00+00:00', '') || ''
            const [year, month, day] = datePart.split('-')
            const formattedDate = `${month}/${day}/${year}`

            return `${formattedDate} à ${timePart}`.trim()
          }} />
        </ReferenceField>

        <ReferenceField
          label="Fin"
          source="calendar_event_id"
          reference="calendar"
        >
          <FunctionField source="end" render={record => {
            if (!record || !record.end) return null

            const datePart = record.end.split('T')[0]
            const timePart = record.end.split('T')[1]?.replace(':00+00:00', '') || ''
            const [year, month, day] = datePart.split('-')
            const formattedDate = `${month}/${day}/${year}`

            return `${formattedDate} à ${timePart}`.trim()
          }} />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

export default LeadRDV
import { useEffect, useState } from 'react'
import { useEditContext } from 'react-admin'

import TasksPubeurs from './TasksPubeurs'
import { TasksInputContent } from './index'
import { supabaseClient } from '../supabase'
import TasksActionsTrees from './TasksActionsTrees'
import { EditDialog } from '@react-admin/ra-form-layout'
import { CustomLeadsTab, CustomLeadTabbedShowLayout } from '../Design/CustomTabs'


const TasksEdit = () => (
  <EditDialog
    title="Fiche tâche"
    maxWidth="md"
    fullWidth
  >
    <TasksEditContent />
  </EditDialog>
)

const TasksEditContent = () => {
  const { record } = useEditContext()

  const [taskPubeurs, setTaskPubeurs] = useState([])
  
  useEffect(() => {
    const getLeadTasksPubeurs = async () => {
      const { data, error } = await supabaseClient
        .rpc('fetch_lead_tasks_pubeurs', { 'lt_id': record?.id })
    
      if (error) {
        console.error('Error fetching tasks leads:', error.message)
        return
      }
    
      setTaskPubeurs(data)
    }
    
    getLeadTasksPubeurs()
  }, [])

  return (
    <CustomLeadTabbedShowLayout style={{ minHeight: '90vh' }}>
      <CustomLeadsTab label="Informations" name="informations">
        <TasksInputContent />
      </CustomLeadsTab>

      <CustomLeadsTab label="Actions" name="actions" path="actions">
        <TasksActionsTrees />
      </CustomLeadsTab>

      { taskPubeurs?.length > 0 && (
        <CustomLeadsTab label={taskPubeurs?.length > 1 ? 'Pubeurs' : 'Pubeur'} path="pubeurs">
          <TasksPubeurs />
        </CustomLeadsTab>
      )}
    </CustomLeadTabbedShowLayout>
  )
}

export default TasksEdit

import React, { useEffect, useState } from "react"
import {
  ChipField,
  useListContext,
  useRedirect,
  useRefresh,
} from "react-admin";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Box,
  Chip,
  Tooltip
} from '@mui/material';

import AddFilterDialog from './ManageFilters'
import createGroupFilters from './groupFilters'
import CurrentFilterButton from './CurrentFilterButton'

import PauseIcon from '@mui/icons-material/Pause'
import CancelIcon from '@mui/icons-material/Cancel'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const LeadsAsideFilters = () => {
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { total, filterValues, setFilters } = useListContext()
  const [totalLeads, setTotalLeads] = useState(null)

  useEffect(() => {
    setTotalLeads(total)
  }, [total, refresh])

  const filterValuesBeforeGrouped = localStorage.getItem('filterValuesBeforeGrouped') ? JSON.parse(localStorage.getItem('filterValuesBeforeGrouped')) : null
  const groupedFilteredValues = localStorage.getItem('groupedFilteredValues') ? JSON.parse(localStorage.getItem('groupedFilteredValues')) : null
  const areFiltersPaused = localStorage.getItem('pauseFilters') ? JSON.parse(localStorage.getItem('pauseFilters')) : false
  const betweenGroupsCondition = localStorage.getItem('betweenGroupsCondition') ? JSON.parse(localStorage.getItem('betweenGroupsCondition')) : 'ET'

  const handlePauseFilters = () => {
    areFiltersPaused ? localStorage.removeItem('pauseFilters') : localStorage.setItem('pauseFilters', true)
    refresh()
  }

  const changeBetweenGroupsCondition = () => {
    betweenGroupsCondition
      ? betweenGroupsCondition === 'and' ? localStorage.setItem('betweenGroupsCondition', JSON.stringify('or')) : localStorage.setItem('betweenGroupsCondition', JSON.stringify('and'))
      : localStorage.setItem('betweenGroupsCondition', JSON.stringify('and'))

    refresh()
  }

  const handleGroupConditionChange = (groupNumber, reverseCondition) => {
    function updateGroupCondition(allFilters, groupNumber, newCondition) {
      Object.keys(allFilters).forEach(outerKey => {
        const filters = allFilters[outerKey]
    
        Object.keys(filters).forEach(innerKey => {
          const filter = filters[innerKey]
    
          if (filter.filterGroup === groupNumber) {
            filter.groupCondition = newCondition
          }
        })
      })

      return allFilters
    }

    const updatedFilterValuesBeforeGrouped = updateGroupCondition(filterValuesBeforeGrouped, groupNumber, reverseCondition)
    localStorage.setItem('filterValuesBeforeGrouped', JSON.stringify(updatedFilterValuesBeforeGrouped))
    const updatedGroupedFilteredValues = createGroupFilters(updatedFilterValuesBeforeGrouped)
    localStorage.setItem('groupedFilteredValues', JSON.stringify(updatedGroupedFilteredValues))

    redirect('/leads')
    refresh()
  }

  const removeGroup = (groupNumber) => {
    function updateGroupFilters(allFilters, groupNumber) {
      Object.keys(allFilters).forEach(outerKey => {
        const filters = allFilters[outerKey]
    
        Object.keys(filters).forEach(innerKey => {
          const filter = filters[innerKey]
    
          if (filter.filterGroup === groupNumber) {
            delete filters[innerKey]
          }
        })

        if ( Object.keys(filters).length === 0 ) {
          delete allFilters[outerKey]
          localStorage.removeItem('pauseFilters')
        }
      })

      return allFilters
    }

    const updatedFilterValuesBeforeGrouped = updateGroupFilters(filterValuesBeforeGrouped, groupNumber)

    if ( Object.keys(updatedFilterValuesBeforeGrouped).length === 0 ) {
      delete filterValues.groupFilters
      setFilters(filterValues)
      localStorage.removeItem('groupedFilteredValues')
      localStorage.removeItem('filterValuesBeforeGrouped')
    } else {
      localStorage.setItem('filterValuesBeforeGrouped', JSON.stringify(updatedFilterValuesBeforeGrouped))
      const updatedGroupedFilteredValues = createGroupFilters(updatedFilterValuesBeforeGrouped)
      localStorage.setItem('groupedFilteredValues', JSON.stringify(updatedGroupedFilteredValues))
    }

    redirect('/leads')
    refresh()
  }

  return groupedFilteredValues && (
    <Box
      minWidth="16vw"
      order="1"
      backgroundColor="#e6f2f1"
      padding="10px 20px 10px 15px"
      marginTop="8vh"
      borderRadius="0px 10px 0px 0px"
      color="#333"
    >
      { groupedFilteredValues && (
        <>
          <Stack direction="row" justifyContent="space-between">
            { areFiltersPaused && <PauseIcon sx={{ fontSize: '1rem', mt: .5, mr: 1 }} /> }

            <Typography variant="body1" style={{ marginBottom: '15px', fontWeight: 'bold', padding: '0px', width: '100%' }}>
              FILTRES ACTIFS
            </Typography>

            <span style={{ fontSize: '.9rem' }}>{totalLeads}</span>
          </Stack>

          { groupedFilteredValues.map((group, groupIndex) => {
            const groupNumber = group.groupNumber
            const groupColor = group.groupColor
            const groupCondition = group.groupCondition
            const reverseCondition = group.groupCondition === 'AND' ? 'OR' : 'AND'
            const groupUniqueID = group.groupUniqueID
            
            if ( ! groupUniqueID ) return
            
            return (
              <div key={groupIndex} style={{ width: '100%' }}>
                {
                  <Stack direction="row" justifyContent="space-between" style={{ margin: '10px -6px 0 0', borderBottom: '2px solid'+ groupColor }}>
                    <Typography variant="subtitle1" style={{ fontSize: '.8rem', fontWeight: 'bold' }}>
                      Contacts :
                    </Typography>

                    <Grid container  style={{ maxWidth: '6vw', margin: '0' }}>
                      <Grid item xs={4}>
                        <AddFilterDialog
                          styles={{ padding: '0px', border: 'none', marginTop: '-7px', minWidth: '35px' }}
                          groupNumber={groupNumber}
                          groupCondition={groupCondition}
                          variant="outlined"
                          label=""
                          icon={<AddCircleOutlineIcon style={{ fontSize: '1.2rem', cursor: 'pointer' }}/>}
                          color="success"
                          isMainButton={false}
                        />
                      </Grid>

                      <Grid item xs={4}>
                      { groupCondition === 'AND'
                        ? (
                            <Tooltip title={ groupCondition === 'AND' ? `Comparer les filtres du groupe` : `Additioner les filtres du groupe` }>
                              <InsertLinkIcon
                                style={{ cursor: 'pointer', fontSize: '1.2rem', color: 'darkgreen' }}
                                onClick={() => handleGroupConditionChange(groupNumber, reverseCondition)}
                                aria-hidden="false" aria-label="Sample Icon"
                              />
                            </Tooltip>
                          )
                        : (
                            <LinkOffIcon
                              style={{ cursor: 'pointer', fontSize: '1.2rem', color: '#00d8ff' }}
                              onClick={() => handleGroupConditionChange(groupNumber, reverseCondition)}
                            />
                          )
                      }
                      </Grid>

                      <Grid item xs={4}>
                        <Tooltip title="Supprimer le groupe">
                          <CancelIcon
                            style={{ cursor: 'pointer', fontSize: '1.2rem', color: '#c93a3a' }}
                            onClick={() => removeGroup(groupNumber, reverseCondition)}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Stack>
                }
                
                { Object.entries(group.groupFilters).map(([filterName, filterEntries], filterIndex) => {
                  const filterUniqueKey = `${groupIndex}-${filterIndex}`

                  return (
                    <React.Fragment key={filterUniqueKey}>
                      { Object.keys(filterEntries).map((entryKey, entryIndex) => {
                        const entryUniqueKey = `${groupIndex}-${filterIndex}-${entryIndex}`
                        const keysToProcess = Object.keys(filterEntries).length > 1 ? {[entryKey]: filterEntries[entryKey]} : filterEntries

                        return (
                          <React.Fragment key={entryUniqueKey}>
                            { Object.keys(keysToProcess).map((key, keyIndex) => {
                              const filterOptions = {
                                ...keysToProcess[key],
                                groupCondition: groupCondition,
                                groupColor: groupColor,
                                groupUniqueID: groupUniqueID,
                                groupNumber: groupNumber,
                              }
                              
                              const { filterTitle, filterLabel } = filterOptions

                              if ( filterName === 'favourited_by'
                                || filterName === 'check_all_favourites'
                                || filterName === 'center_id'
                                || filterName === 'stop_rgpd'
                              ) return

                              let newFilterName
                              
                              if ( ! filterTitle ) {
                                newFilterName = filterName.replace('first_name@ilike', 'Prénom')
                                newFilterName = newFilterName.replace('last_name@ilike', 'Nom')
                                newFilterName = newFilterName.replace('email@ilike', 'Email')
                                newFilterName = newFilterName.replace('phone@ilike', 'Téléphone')
                                newFilterName = newFilterName.replace(/_/g, ' ')
                                newFilterName = newFilterName.replace('check all favourites', 'Tous les favoris')
                                newFilterName = newFilterName.replace('registered passlas', 'Inscrit(e) PASS/L.AS')
                                newFilterName = newFilterName.replace('registered ts', 'Inscrit(e) TS')
                                newFilterName = newFilterName.replace('registered', 'Inscrit(e)')
                                newFilterName = newFilterName.replace('not interested', 'Pas intéressé(e) par la prépa')
                                newFilterName = newFilterName.replace('interested', 'Intéressé(e) par la prépa')
                                newFilterName = newFilterName.replace('stop rgpd', 'Stop RGPD')
                                newFilterName = newFilterName.replace('assigned pubeur', 'Pubeur assigné')
                                newFilterName = newFilterName.charAt(0).toUpperCase() + newFilterName.slice(1)

                                newFilterName = newFilterName.replace(' '+ groupUniqueID, '')
                              }

                              let newFilterLabel
                              if ( typeof filterOptions === 'object' && ! filterLabel ) {
                                newFilterLabel = 'Autre'
                              }

                              const filterIntitule = newFilterName ?? filterLabel ?? newFilterLabel
                              const filterDescription = filterTitle ?? null
                              const filterKey = `${groupIndex}-${filterIndex}-${entryIndex}-${keyIndex}`

                              const filterInformation = {
                                filterNameEncoded: filterName,
                                filterIntitule,
                                filterDescription,
                                groupCondition,
                                groupColor,
                                groupUniqueID,
                                // filterUniqueId,
                              }
                              
                              return (
                                <React.Fragment key={filterKey}>
                                  <CurrentFilterButton
                                    groupIndex={groupIndex}
                                    entryKey={entryKey}
                                    filterOptions={filterOptions}
                                    filterInformation={filterInformation}
                                    group={group}
                                  />
                                </React.Fragment>
                              )
                          }) }

                          </React.Fragment>
                        )

                      } )}
                    </React.Fragment>
                  )
                } )}

                { groupedFilteredValues.length > groupNumber && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItmes: 'center' }}>
                    <Tooltip title={
                      betweenGroupsCondition === 'and'
                        ? 'Changer et comparer les groupes'
                        : 'Changer et additionner les groupes'
                    }>
                      <Chip
                        label={betweenGroupsCondition === 'and' ? 'ET' : 'OU'}
                        style={{
                          fontSize: '1rem', fontWeight: 'bold', textAlign: 'center', margin: '15px 0 0 0',
                          backgroundColor: '#ccc', padding: '4px', display: 'inline-block',
                        }}
                        onClick={() => changeBetweenGroupsCondition()}
                      />
                    </Tooltip>
                  </div>
                ) }
              </div>
            )
          }) }

          <AddFilterDialog
            groupNumber={Object.keys(groupedFilteredValues).length + 1}
            label="Nouveau groupe"
            color="success"
            styles={{ margin: '30px auto 0 auto', fontSize: '.75rem', width: '100%' }}
            isMainButton={false}
          />

          <Button
            style={{ margin: '6px auto 0 auto', fontSize: '.75rem', width: '100%' }}
            onClick={() => handlePauseFilters()}
            variant="outlined"
            color="info"
          >
            { areFiltersPaused ? 'Réactiver les filters' : 'Suspendre les filtres' }
          </Button>

          <Button
            style={{ margin: '6px auto 0 auto', fontSize: '.75rem', width: '100%' }}
            onClick={() => {
              setFilters({});
              localStorage.removeItem('filterValuesBeforeGrouped')
              localStorage.removeItem('groupedFilteredValues')
              localStorage.removeItem('pauseFilters')
              redirect('/leads')
              refresh()
            }}
            variant="outlined"
            color="error"
          >
            Effacer tout
          </Button>
        </>
      ) }
    </Box>
  )
}

export default LeadsAsideFilters
import { Loading, useGetOne } from 'react-admin';

const roles = [
    { id: 100, name: 'Admin', reference: 'admin' },
    { id: 50, name: 'Responsable', reference: 'responsable' },
    { id: 20, name: 'Coordinateur', reference: 'coordinateur' },
    { id: 10, name: 'Prof', reference: 'prof' },
    { id: 0, name: 'Pubeur', reference: 'pubeur' },
    { id: -1, name: 'Parrain / Marraine', reference: 'Godfather' },
    { id: -2, name: 'Tuteur', reference: 'tutor' },
];

export const getRoles = role => {
    return roles?.filter(r => r.id <= role);
};

export const getRole = role => {
    const result = roles.filter(currentRole => currentRole.reference == role);
    return result[0]?.id;
};

export const getRoleById = id => {
    const result = roles.filter(currentRole => currentRole.id == id);
    return result[0]?.reference;
};

export const GetRoleBySupabaseId = (record) => {
    const { data, isLoading, error } = useGetOne('roles', { id: record });
    if (isLoading) { return <Loading /> }

    if (error) { return <p>ERROR</p> }

    return data.grade
};

export default roles
import { useState } from 'react'
import {
  required,
  useCreateContext,
  useEditContext,
  useNotify,
  useRecordContext,
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  TextInput,
  useGetIdentity,
} from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { SimpleForm } from '@react-admin/ra-rbac'
import { RichTextInput } from 'ra-input-rich-text'

import ConversationsList from './ConversationsList'
import { toLowerCase } from '../common/Formating'
import { TextInputCopy } from '../Design/CustomTextInput'
import { filterBlankSpaces } from '../common/customTextFilter'

import BookmarkIcon from '@mui/icons-material/Bookmark'

export default {
  list: ConversationsList,
  icon: BookmarkIcon,
  options: { label: 'Conversations' },
}

const transformData = data => {
  const { name, ...rest } = data

  return {
    name: name,
    ...rest,
  }
}

export const ConversationsInputContent = props => {
  const record = useRecordContext()
  const notify = useNotify()
  const { identity } = useGetIdentity()
  const editContext = useEditContext()
  const createContext = useCreateContext()

  const role = identity?.role?.reference

  const [chosenCenter, setChosenCenter] = useState(identity?.center?.id)

  const handleSubmit = async (values, form) => {
    values.year_id = JSON.parse(localStorage.getItem('localCurrentYear'))?.id || process.env.REACT_APP_CURRENT_YEAR_ID
    values.center_id = chosenCenter || identity?.center?.id

    const { id, ...transformedData } = transformData(values)

    try {
      return record?.id
        ? editContext.save(transformedData, form)
        : createContext.save(transformedData, form)
    } catch (error) {
      console.error('An error occurred:', error)
      notify('Une erreur est survenue', { type: 'error' })
    }
  }

  return (
    <SimpleForm
      {...props}
      shouldUnregister
      onSubmit={handleSubmit}
    >
      { record?.id && (
        <TextInputCopy label="ID" source="id" variant="outlined" fullWidth readOnly />
      )}
      
      { role === 'admin' && (
        <ReferenceInput
          source="center_id"
          reference="centers"
        >
          <AutocompleteInput
            label="Centre"
            optionText="name"
            filterToQuery={searchText => filterBlankSpaces(searchText)}
            onChange={(value, event) => setChosenCenter(value)}
            validate={[required()]}
            variant="outlined"
            fullWidth
          />
        </ReferenceInput>
      )}

      <TextInput label="Intitulé" source="name" validate={[required()]} variant="outlined" autoFocus fullWidth />
      <TextInput label="Couleur hexadécimale" source="color" variant="outlined" fullWidth />
    </SimpleForm>
  )
}
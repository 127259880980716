import { Card, CardContent, Typography, useMediaQuery } from '@mui/material'

export const FormCard = ({ colorTitle = '#287791', title, cardWidth, children }) => {
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('sm'),
    { noSsr: true }
  )

  const trueCardWidth = isMobile ? '100%' : cardWidth ?? '100%'

  return (
    <Card variant="outlined" sx={{ mt: 2, width: trueCardWidth, mb: 2, backgroundColor: '#f2f2f2' }}>
      <CardContent sx={{ '& .MuiInputBase-root': { backgroundColor: '#fff' } }}>
        {title && (
          <Typography variant="h6" color={colorTitle} mt={2} mb={2} gutterBottom>
            {title}
          </Typography>
        )}
        {children}
      </CardContent>
    </Card>
  )
}

import { useGetIdentity, } from 'react-admin'

import { getRole } from '../common/roles'
import { classes } from '../common/classes'
import SchoolBilanPerYear from './SchoolBilanPerYear'

const SchoolBilan = () => {
  const { identity } = useGetIdentity()
  const localCenter = identity?.role?.grade < getRole('admin') ? identity?.center : null

  const classOptions = classes[localCenter?.id]?.options ?? classes['default'].options

  return (
    <>
      <SchoolBilanPerYear title="Bilan 2024/2025" tableview="school_leads_count" classOptions={classOptions} identity={identity} />
      <SchoolBilanPerYear title="Bilan 2023/2024" tableview="school_leads_count2324" classOptions={classOptions} identity={identity} />
      <SchoolBilanPerYear title="Bilan 2022/2023" tableview="school_leads_count2223" classOptions={classOptions} identity={identity} />
    </>
  )
}

export default SchoolBilan
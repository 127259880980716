import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  Link,
} from 'react-admin'
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ReferenceManyToManyField } from "@react-admin/ra-relationships"

import { classes } from '../common/classes'

const SchoolEvents = ({ localCenter }) => {
  const eventTypeFilters = [
    <ReferenceInput
      label="Type"
      source="type"
      reference="eventTypes"
      alwaysOn
    >
      <AutocompleteInput
        fullWidth
        optionText="name"
        filterToQuery={searchText => ({ name: searchText })}
        options={{ type: 'search' }}
      />
    </ReferenceInput>,
  ]

  const SchoolEventExpand = ({ record }) => (
    <Box sx={{ p: 2, backgroundColor: '#ebf3f4', borderRadius: '10px' }}>
      <Typography variant="body1" gutterBottom><u>Bilan des contacts pour {record.name}</u></Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {(classes[localCenter?.id]?.options ?? classes['default'].options).map((classe, key) => (
                <TableCell key={key}>
                  {`${classe?.name}s`}
                </TableCell>
              ))}
              <TableCell>Sans classe</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              {(classes[localCenter?.id]?.options ?? classes['default'].options).map((classe, key) => (
                <TableCell key={key}>
                  <ReferenceField
                    label={`${classe?.name}s`}
                    source="id" 
                    reference="event_leads_count"
                    link={false}
                  >
                    <NumberField source={`class_id_${classe?.id}`} />
                  </ReferenceField>
                </TableCell>
              ))}

              <TableCell>
                <ReferenceField
                  label="Sans classe"
                  source="id"
                  reference="event_leads_count"
                  link={false}
                >
                  <NumberField source={`class_id_9999`} />
                </ReferenceField>
              </TableCell>

              <TableCell>
                <ReferenceField
                  label="Total"
                  source="id" 
                  reference="event_leads_count"
                  link={false}
                >
                  <NumberField source={`total_leads`} />
                </ReferenceField>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )

  return (
    <ReferenceManyToManyField
      reference="events"
      through="event_schools"
      using="school_id,event_id"
      perPage={10}
      filters={eventTypeFilters}
    >
      <Datagrid bulkActionButtons={false} expand={<SchoolEventExpand />} expandSingle>
        <ReferenceField label="Catégorie" source="type_id" reference="types">
          <TextField source="name" />
        </ReferenceField>

        <FunctionField label="Nom" render={record => (
          <Link to={`/events/${record.id}`}>
            {record.name}
          </Link>
        )} />
        
        <DateField label="Date début" source="date" />
      
        <ReferenceManyToManyField
          label="Pubeurs"
          reference="profiles"
          through="event_pubeurs"
          using="event_id,pubeur_id"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField label="Prénom" source="first_name" />
            <TextField label="Nom" source="last_name" />
          </Datagrid>
        </ReferenceManyToManyField>
      </Datagrid>
    </ReferenceManyToManyField>
  )
}

export default SchoolEvents
import { useState, forwardRef } from 'react'
import {
  useListContext,
  Button,
  CreateButton,
  ExportButton,
  SelectColumnsButton,
  TopToolbar,
  useGetIdentity,
  useDataProvider,
} from 'react-admin'
import { Typography, Divider } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

import { IfCanAccess } from '@react-admin/ra-rbac'
import { useTour } from '@react-admin/ra-tour'

import { getRole } from '../common/roles'
import { CommonDialog } from '../common/Dialog'
import { ImportLeadButton } from './LeadsImport'
import { LeadsFilters } from './filters/LeadsFilters'
import { headersOnlyExporter } from './LeadModelExport'

import CancelIcon from '@mui/icons-material/Cancel'
import ListAltIcon from '@mui/icons-material/ListAlt'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PublishIcon from '@mui/icons-material/Publish'

const CustomCreateButton = forwardRef((props, ref) => {
  const { ...rest } = props

  return (
  <span ref={ref} {...rest}>
    <CreateButton {...rest} />
  </span>
)})

const CustomExportButton = forwardRef((props, ref) => {
  const { exporter, selectedIds, setExportLoading, exportLoading, ...rest } = props

  const handleExport = async (records) => {
    try {
      setExportLoading(true)
      await (selectedIds.length > 0 ? exporter(selectedIds) : exporter(records))
      setExportLoading(undefined)
    } catch (error) {
      setExportLoading(undefined)
    }
  }

  return (
    <span ref={ref} {...rest}>
      <ExportButton
        label=""
        exporter={handleExport}
        maxResults={100000}
        disabled={exportLoading}
        sx={{ minWidth: '40px' }}
        {...rest}
      />
    </span>
  )
})

const CustomExportButtonTemplate = forwardRef((props, ref) => {
  const { exporter, icon, ...rest } = props

  return (
    <span ref={ref} {...rest}>
      <ExportButton
        label=""
        icon={icon}
        exporter={exporter}
        sx={{ minWidth: '40px' }}
      />
    </span>
  )
})

const CustomRefButton = forwardRef((props, ref) => {
  const { icon, ...rest } = props
  
  return (
    <span ref={ref} { ...rest }>
      <Button startIcon={icon} {...rest} />
    </span>
  )
})

const WarningBeforeImportLeadButton = ({ label }) => {
  const { identity } = useGetIdentity()
  const [{ running }, { start }] = useTour()
  const [open, setOpen] = useState(false)
  const [openingSteps, setOpeningSteps] = useState(1)

  return (
    <>
      <CommonDialog
        open={open}
        handleClose={() => setOpen(false)}
        icon={<PublishIcon />}
        title="Importer de nouveaux contacts"
        fullWidth
        size="sm"
        style={{ zIndex: 999999 }}
      >
        <Typography variant="body1"><b>Attention, avant d'importer votre fichier, veuillez vérifier que :</b></Typography>

        <Typography variant="body1" mt={2}>
          - Votre fichier est bien au format <u>.CSV UTF-8</u><br/>
          - Votre fichier ne contient pas de coquilles dans les en-têtes : <u>vérifier votre version du template d'import</u><br/>
          - Les lignes de votre fichier ne contiennent pas de coquilles : texte au lieu d'ID, texte au lieu d'un numéro de téléphone, adresse email mal tappée...
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <ImportLeadButton variant="contained" label="Importer" />
        </div>
      </CommonDialog>

      <Tooltip title="Importer">
        <CustomRefButton
          icon={<PublishIcon />}
          onClick={() => {
            identity?.tours?.TourImportEventQualifications
              ? setOpen(true)
              : (
                openingSteps < 2
                  ? start('TourImportEventQualifications')
                  : setOpen(true)
              )
            setOpeningSteps(openingSteps+1)
            setOpen( identity?.tours?.TourImportEventQualifications
              ? true
              : openingSteps > 1 ? true : false )
          }}
          label={label}
          disabled={running ? true : undefined}
          data-tour-id='template-import-button'
          sx={{ minWidth: '40px' }}
        />
      </Tooltip>
    </>
  )
}

export const LeadActions = ({ exporter, ...props }) => {
  const { identity } = useGetIdentity()
  const dataProvider = useDataProvider()
  const localCurrentYear = JSON.parse(localStorage.getItem('localCurrentYear'))?.id || process.env.REACT_APP_CURRENT_YEAR_ID
  const { sort, pagination, selectedIds, setSort, filterValues } = useListContext()
  const [exportLoading, setExportLoading] = useState(undefined)

  const params = {
    filter: filterValues,
    pagination: {
      page: pagination?.page,
      perPage: pagination?.perPage,
    },
    sort: {
      field: sort?.field,
      order: sort?.order,
    },
  }

  const handRefetchLeads = () => {
    const refetch = async () => {
      await dataProvider.refetchData('leads_view', params)
    }

    refetch()
  }

  return (
    <TopToolbar
      sx={{ 
        display: 'flex !important',
        justifyContent: 'space-between !important',
        alignItems: 'center !important',
        position: 'relative !important',
        width: '100%',
        paddingBottom: 0,
        padding: 0
      }}
    >
      <div id="menuLeft" style={{ display: 'flex', flexDirection: 'row' }}>
        <IfCanAccess action="create">
          { localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID && (
            <Tooltip title="Ajouter un contact">
              <CustomCreateButton icon={<AddCircleIcon />} label="" />
            </Tooltip>
          )}
        </IfCanAccess>

        <IfCanAccess action="export">
          { exporter && (
            <>
              <WarningBeforeImportLeadButton label="" {...props} />

              <Tooltip title="Exporter">
                <CustomExportButton
                  exporter={exporter}
                  selectedIds={selectedIds}
                  exportLoading={exportLoading}
                  setExportLoading={setExportLoading}
                />
              </Tooltip>

              <Tooltip title="Template d'export">
                <CustomExportButtonTemplate
                  exporter={headersOnlyExporter}
                  icon={<ListAltIcon />}
                  label=""
                  sx={{ minWidth: '50px' }}
                />
              </Tooltip>
            </>
          )}
        </IfCanAccess>

        <Divider orientation="vertical" flexItem />

        { identity?.role?.grade === getRole('admin') &&
          <SelectColumnsButton preferenceKey="admin.datagrid" />
        }

        { identity?.role?.grade === getRole('responsable') &&
          <SelectColumnsButton preferenceKey="respo.datagrid" />
        }

        { identity?.role?.grade < getRole('responsable') &&
          <SelectColumnsButton preferenceKey="pubeur.datagrid" />
        }

        { filterValues && Object.keys(filterValues).length > 0 && (
          <>
            <Divider orientation="vertical" flexItem />

            <Button
              label="Filtres actifs. Cliquez ici pour rafraîchir vos contacts."
              onClick={handRefetchLeads}
              color="customPrimary"
            />
          </>
        )}
      </div>
      
      <div id="filters">
        <LeadsFilters />

        { ( sort.field !== 'updated_at' || sort.order !== 'DESC' ) && (
          <Button color="error" label="triage" onClick={() => setSort({ field: 'updated_at', order: 'DESC' })}><CancelIcon /></Button>
        )}
      </div>
    </TopToolbar>
  )
}
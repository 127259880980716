import { useState } from 'react'
import {
  useRefresh,
  useNotify,
  useGetIdentity,
  useUnselectAll,
  List,
  Datagrid,
  TextField,
  FunctionField,
  Button,
  ReferenceField,
  useListContext,
} from 'react-admin'

import sendMail from '../../common/SendMail'
import { getRole } from '../../common/roles'
import { supabaseClient } from '../../supabase'
import { CommonDialog } from '../../common/Dialog'
import { PostPagination } from '../../common/Pagination'
import createNotification from '../../common/createNotification'

import BookmarkIcon from '@mui/icons-material/Bookmark'

const LeadTagsAssignationContent = ({ saveTagAssignation }) => {
  const { identity } = useGetIdentity()
  const localCenter = identity?.center

  return (
    <List
      empty={false}
      disableSyncWithLocation
      pagination={<PostPagination />}
      perPage={50}
      resource="tags"
      exporter={false}
      filter={ identity?.role?.grade === getRole('admin') ? {} : { 'center_id' : localCenter?.id } }
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Intitulé" source="name" />

        { identity?.role?.grade >= getRole('responsable') && (
          <ReferenceField label="Centre" source="center_id" reference="centers" link={false}>
            <TextField source="name"/>
          </ReferenceField>
        )}

        <FunctionField render={
          record => (
            <>
              <Button
                label="Assigner ce tag"
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => saveTagAssignation('assignTag', record.id)}
                sx={{ mr: 1 }}
              />

              <Button
                label="Retirer ce tag"
                variant="outlined"
                color="warning"
                size="small"
                onClick={() => saveTagAssignation('unassignTag', record.id)}
              />
            </>
          )
        }/>
      </Datagrid>
    </List>
  )
}

export const TagAssignation = () => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll('leads')
  const { selectedIds: leads } = useListContext()

  const [openTagAssignation, setOpenTagAssignation] = useState(false)

  const performTagAssignation = async (action, chunk, tagId) => {
    for ( const lead of chunk ) {
      // first check if tag is already assigned
      const { data: existingTags, error: errorExistingTags } = await supabaseClient
        .from('leads')
        .select('tags_ids')
        .eq('id', lead)

      if (errorExistingTags) {
        console.error('Error fetching existing tags: ', errorExistingTags)
        return
      }

      await Promise.all([existingTags])

      if ( action === 'unassignTag' ) {
        const removedTag = existingTags[0]?.tags_ids ? existingTags[0].tags_ids.filter(tag => tag !== tagId) : []

        const { error: errorInsertTag } = await supabaseClient
          .from('leads')
          .update({ tags_ids: removedTag })
          .eq('id', lead)

        if (errorInsertTag) {
          console.error('Error removing tag: ', errorInsertTag)
        }
  
        notify(`Tag désassigné avec succès`, {'type' : 'success'})
        refresh()
        unselectAll()
        setOpenTagAssignation(false)
        return
      }

      const newTags = existingTags[0]?.tags_ids ? [...existingTags[0].tags_ids, tagId] : [tagId]

      const { error: errorInsertTag } = await supabaseClient
        .from('leads')
        .update({ tags_ids: newTags })
        .eq('id', lead)

      if (errorInsertTag) {
        console.error('Error inserting tag: ', errorInsertTag)
      }

      notify(`Tag assigné avec succès`, {'type' : 'success'})
      refresh()
      unselectAll()
      setOpenTagAssignation(false)
    }
  }

  const saveTagAssignation = (action, tagId) => {
    const chunkSize = 100
    const chunks = []

    for ( let i = 0; i < leads?.length; i += chunkSize ) {
      chunks.push(leads.slice(i, i + chunkSize))
    }

    if ( ! action ) return

    chunks.forEach(chunk => {
      performTagAssignation(action, chunk, tagId)
    })
  }

  return (
    <>
      <CommonDialog
        open={openTagAssignation}
        handleClose={() => setOpenTagAssignation(false)}
        title={ leads?.length > 1 ?
          `Assignation d'une tâche sur ${leads?.length} contacts sélectionnés` :
          `Assignation d'une tâche sur le contact sélectionné`
        }
        size='md'
      >
        <LeadTagsAssignationContent saveTagAssignation={saveTagAssignation} />
      </CommonDialog>

      <Button
        label="Tags"
        startIcon={<BookmarkIcon style={{ fontSize: '1rem'}} />}
        size="small"
        color="primary"
        onClick={() => setOpenTagAssignation(true)}
        sx={{ fontSize: '.8rem', minWidth: '80px' }}
      />
    </>
  )
}

export default TagAssignation
import { CreateDialog } from '@react-admin/ra-form-layout'
import { useGetIdentity } from 'react-admin'
import { ContentEventsInput } from '.'
import sendMail from '../common/SendMail'
import { supabaseClient } from '../supabase'

const CalendarEventsCreate = () => {
  const { identity } = useGetIdentity()

  const handleTransform = (eventData) => {
    // const { start, end, profile_id, contact_id, center_id, ...rest } = eventData
    const { title, dateRDV, timeStart, timeEnd, profile_id, center_id } = eventData

    let datePart = dateRDV // 'YYYY-MM-DD'

    // Extract the time components from timeStart and timeEnd
    let timeStartPart = timeStart.toTimeString().split(' ')[0] // 'HH:mm:ss'
    let timeEndPart = timeEnd.toTimeString().split(' ')[0]     // 'HH:mm:ss'

    // Combine the date with the time parts to create new Date objects
    let dateStart = new Date(`${datePart}T${timeStartPart}+02:00`) // explicitly stating GMT+2 offset
    let dateEnd = new Date(`${datePart}T${timeEndPart}+02:00`)     // explicitly stating GMT+2 offset

    let dateToSendMail = new Date(dateStart)
    dateToSendMail.setHours(dateToSendMail.getHours() - 2)

    if ( profile_id ) {
      // create email notification
      // format date nice display
      const options = { day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit', hour12: false }
      const formattedDate = dateStart.toLocaleDateString('fr-FR', options)

      const messageHTML = `
        <h3>Rappel de rendez-vous avec un contact</h3>

        <h4>Vous avez un rendez-vous programmé pour aujourd'hui le ${formattedDate}.</h4>

        <p>
          <a href="https://forma-contacts.fr/leads/${profile_id}">Cliquez ici pour consulter la fiche contact.</a>
        </p>

        <p>
          <i>Ceci est un message automatique, merci de ne pas y répondre.</i>
        </p>
      `

      const messageTEXT = `RAPPEL DE RDV AVEC UN CONTACT\n\nUn rendez-vous a été programmé aujourd'hui le ${formattedDate}\n\nCliquez ici pour consulter la fiche contact: https://forma-contacts.fr/leads/${profile_id}\n\nCeci est un message automatique, merci de ne pas y répondre.`

      sendMail ({
        identity: identity,
        subject: `Rendez-vous programmé avec un contact aujourd'hui`,
        messageHTML: messageHTML,
        messageTEXT: messageTEXT,
        date: dateToSendMail,
        sendNow: false
      })
    }
  
    return {
      title,
      allDay: false,
      start: dateStart,
      end: dateEnd,
      profile_id: profile_id ?? identity?.id,
      center_id: center_id ?? identity?.center?.id,
    }
  }
  
  return (
    <CreateDialog transform={handleTransform}>
      <ContentEventsInput />
    </CreateDialog>
  )
}

export default CalendarEventsCreate
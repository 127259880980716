import {
  email,
  required,
  useRecordContext,
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useGetIdentity,
  BooleanInput,
} from 'react-admin'
import { Grid, Typography, Stack } from '@mui/material'
import { ReferenceManyInput } from '@react-admin/ra-relationships'

import SchoolList from './SchoolList'
import { getRole } from '../common/roles'
import { FormCard } from '../Design/Cards'
import { classes } from '../common/classes'
import SchoolBilanPerYear from './SchoolBilanPerYear'
import { TextInputCopy } from '../Design/CustomTextInput'
import { filterBlankSpaces } from '../common/customTextFilter'
import { isFormPhoneValid } from '../common/Formating'

import BusinessIcon from '@mui/icons-material/Business'

export default {
  list: SchoolList,
  icon: BusinessIcon,
  options: {
    'label': 'Lycées',
  } 
}

export const SchoolInputContent = props => {
  const record = useRecordContext()
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const isPubeur = identity?.role?.grade === getRole('pubeur')

  const localCenter = identity?.role?.grade < getRole('admin') ? identity?.center : null
  const classOptions = classes[localCenter?.id]?.options ?? classes['default'].options

  return (
    <SimpleForm {...props}>
      { record?.id && !isPubeur && <TextInputCopy source="id" label="ID" fullWidth readOnly /> }

      { ! isPubeur && (
        <FormCard mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextInput fullWidth label="Nom" source="name" variant="outlined" validate={[required()]} readOnly={isPubeur} autoFocus />
            </Grid>

            <Grid item xs={12} md={4}>
              <ReferenceInput
                source="center_id"
                reference="centers"
              >
                <AutocompleteInput
                  label="Centre"
                  optionText="name"
                  variant="outlined"
                  filterToQuery={searchText => filterBlankSpaces(searchText)}
                  validate={[required()]}
                  defaultValue={isAdmin ? null : identity?.center?.id }
                  readOnly={ ! isAdmin }
                  fullWidth
                />
              </ReferenceInput>
            </Grid>
          </Grid>
        </FormCard>
      )}

      <Stack spacing={2} mb={2} direction="row" alignItems="top" justifyContent="space-between">
        <FormCard cardWidth="50%">
          <TextInput fullWidth label="Téléphone principal" source="phone" variant="outlined" validate={[required(), isFormPhoneValid]} readOnly={isPubeur} />
          <TextInput fullWidth label="Email principal" source="email" variant="outlined" validate={[email()]} readOnly={isPubeur} />
          <TextInput fullWidth label="Rue" source="address.street" variant="outlined" validate={[required()]} readOnly={isPubeur} />

          <Grid container spacing={2}>
            <Grid item xs={4} md={4}>
              <TextInput label="Code postal" source="address.postalCode" variant="outlined" validate={[required()]} readOnly={isPubeur} fullWidth />
            </Grid>

            <Grid item xs={8} md={8}>
              <TextInput label="Ville" source="address.city" variant="outlined" validate={[required()]} readOnly={isPubeur} fullWidth />
            </Grid>
          </Grid>

          { ! isPubeur && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextInput fullWidth label="Rang" source="rank" variant="outlined" readOnly={isPubeur} />
              </Grid>

              <Grid item xs={12} md={7} mt={1}>
                <BooleanInput label="Lycée privé" source="isPrivate" readOnly={isPubeur} />
                <BooleanInput label="Lycée partenaire" source="isPartner" readOnly={isPubeur} />
              </Grid>
            </Grid>
          )}
        </FormCard>
        
        { isPubeur ? (
          <SchoolBilanPerYear cardWidth="50%" title="Bilan 2023/2024" tableview="school_leads_count2324" classOptions={classOptions} identity={identity} />
        ) : (
          <FormCard cardWidth="50%">
            <NumberInput fullWidth label="Km depuis la prépa" variant="outlined" source="distance_from_center" readOnly={isPubeur} />
            <NumberInput fullWidth label="Nombre de secondes" variant="outlined" source="secondes_count" readOnly={isPubeur} />
            <NumberInput fullWidth label="Nombre de premières" variant="outlined" source="first_year_count" readOnly={isPubeur} />
            <NumberInput fullWidth label="Nombre de premières + 2" variant="outlined" source="first_year_count_plus" readOnly={isPubeur} />
            <NumberInput fullWidth label="Nombre de terminales" variant="outlined" source="last_year_count" readOnly={isPubeur} />
            <NumberInput fullWidth label="Nombre de terminales + 2" variant="outlined" source="last_year_count_plus" readOnly={isPubeur} />
          </FormCard>
        )}
      </Stack>

      <FormCard mb={2}>
        <TextInput label="Commentaire sur ce lycée" source="comment" variant="outlined" multiline rows={7} fullWidth />
      </FormCard>

      <FormCard mb={2}>
        <Typography color="textSecondary" variant="body2">
          Endroits stratégiques pour se placer
        </Typography>

        <ReferenceManyInput label={false} reference="school_exits" target="school_id">
          <SimpleFormIterator fullWidth inline>
            <TextInput label="Rue" source="street" variant="outlined" fullWidth />
          </SimpleFormIterator>
        </ReferenceManyInput>
      </FormCard>
    </SimpleForm>
  )
}

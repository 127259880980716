import { useState } from 'react'
import {
  required,
  useCreateContext,
  useEditContext,
  useNotify,
  useRecordContext,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  useGetIdentity,
  SaveContextProvider,
  useSaveContext,
  Button,
  FormDataConsumer,
  SaveButton,
  DeleteButton,
  Toolbar
} from 'react-admin'
import { Typography, Grid, Stack } from '@mui/material'
import { SimpleForm } from '@react-admin/ra-rbac'

import Wheel from '@uiw/react-color-wheel'
import { hexToHsva, validHex, hsvaToHex } from '@uiw/color-convert'

import TagsList from './TagsList'
import { getRole } from '../common/roles'
import { TextInputCopy } from '../Design/CustomTextInput'
import { filterBlankSpaces } from '../common/customTextFilter'

import BookmarkIcon from '@mui/icons-material/Bookmark'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export default {
  list: TagsList,
  icon: BookmarkIcon,
  options: { label: 'Tags' },
}

const transformData = data => {
  const { name, ...rest } = data

  return {
    name: name,
    ...rest,
  }
}

const CustomSaveButtons = props => {
  const { save, ...rest } = props
  const saveContext = useSaveContext()

  return (
    <Toolbar sx={{ justifyContent: 'space-between', marginTop: '50px' }}>
      <SaveButton
        {...rest}
        save={save}
        saving={saveContext.saving}
        handleSubmitWithRedirect={saveContext.handleSubmitWithRedirect}
        alwaysEnable
      />

      <DeleteButton {...rest} />
    </Toolbar>
  )
}

export const TagsInputContent = props => {
  const record = useRecordContext()
  const notify = useNotify()

  const { identity } = useGetIdentity()
  const role = identity?.role?.grade

  const editContext = useEditContext()
  const createContext = useCreateContext()
  
  const editColor = record?.color && validHex(`#${record?.color}`) ? hexToHsva(`#${record?.color}`) : null
  const [hsva, setHsva] = useState(editColor ?? { h: 214, s: 43, v: 90, a: 1 })
  const hexColor = hsvaToHex(hsva)
  const [colorSelected, setColorSelected] = useState(false)
  const [resetColor, setResetColor] = useState(false)

  const [chosenCenter, setChosenCenter] = useState(record?.center_id)

  const handleSubmit = async (values, form) => {
    values.year_id = JSON.parse(localStorage.getItem('localCurrentYear'))?.id || process.env.REACT_APP_CURRENT_YEAR_ID
    values.center_id = chosenCenter || identity?.center?.id
    values.color = resetColor ? null : hexColor.replace('#', '') ?? record?.color ?? null

    const { id, ...transformedData } = transformData(values)

    try {
      return record?.id
        ? editContext.save(transformedData, form)
        : createContext.save(transformedData, form)
    } catch (error) {
      console.error('An error occurred:', error)
      notify('Une erreur est survenue', { type: 'error' })
    }
  }

  const isNotEditable = record
    ? record?.name === 'Intéressé(e) par la prépa' || record?.name === 'Pas intéressé(e) par la prépa' || record?.name === 'Stop RGPD'
    : false

  return (
    <SimpleForm
      {...props}
      shouldUnregister
      onSubmit={handleSubmit}
      toolbar={<CustomSaveButtons />}
    >
      { record?.id && (
        <TextInputCopy label="ID" source="id" variant="outlined" fullWidth readOnly />
      )}
  
      { role === getRole('admin') && (
        <ReferenceInput
          source="center_id"
          reference="centers"
        >
          <AutocompleteInput
            label="Centre"
            optionText="name"
            filterToQuery={searchText => filterBlankSpaces(searchText)}
            onChange={(value, event) => setChosenCenter(value)}
            validate={[required()]}
            variant="outlined"
            readOnly={isNotEditable}
            fullWidth
          />
        </ReferenceInput>
      )}

      <TextInput label="Intitulé" source="name" validate={[required()]} variant="outlined" readOnly={isNotEditable} autoFocus fullWidth />

      { ! isNotEditable && (
        <>
          <Typography variant="h6" gutterBottom>Choisissez une couleur</Typography>
        
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Wheel color={hsva} onChange={(color) => {
                setColorSelected(true)
                setResetColor(false)
                setHsva({ ...hsva, ...color.hsva })
              }} width="100" height="100" style={{ width: '100px', height: '100px' }} />
            </Grid>

            <Grid item xs={12} md={4}>
              { record?.color &&
                <Button
                  label="Retirer la couleur"
                  variant="contained"
                  color="warning"
                  readOnly={isNotEditable}
                  onClick={() => {
                    setResetColor(true)
                    setColorSelected(false)
                  }}
                />
              }

              { ! colorSelected ?
                resetColor ? <p>Couleur retirée</p> : <p><CheckCircleIcon sx={{ color: record?.color ? `#${record?.color}` : '#eee' }} /></p>
                : null
              }
              { colorSelected && <p><CheckCircleIcon sx={{ color: hexColor }} /></p> }
            </Grid>
          </Grid>
        </>
      )}
    </SimpleForm>
  )
}
import * as React from 'react'
import { Fragment } from 'react'
import {
  useRefresh,
  useUpdateMany,
  BooleanField,
  BulkDeleteWithConfirmButton,
  Button,
  CreateButton,
  EmailField,
  FilterButton,
  FilterForm,
  ReferenceField,
  TextField,
  TopToolbar,
  useListContext,
  NumberField,
  FunctionField,
  useGetIdentity,
} from 'react-admin'
import { List, Datagrid, IfCanAccess } from '@react-admin/ra-rbac'
import { Stack } from '@mui/material'

import ProfileShow from './ProfileShow'
import ProfileEdit from './ProfileEdit'
import ProfileCreate from './ProfileCreate'
import { ImportProfileButton } from './ProfileImport'
import { PostPagination } from '../common/Pagination'
import { ProfileListFilter } from './ProfileListFilter'

import CancelIcon from '@mui/icons-material/Cancel'
import { getRole } from '../common/roles'

const CustomActions = props => {
  const { sort, filterValues, setSort, setFilters } = useListContext()

  return (
    <TopToolbar sx={{ 
      display: 'flex !important',
      justifyContent: 'left !important',
      alignItems: 'center !important',
      position: 'relative !important',
      width: '100%',
      paddingBottom: 0,
      minHeight: '60px',
      zIndex: 1000
    }}>
      { ( sort.field !== 'created_at' || sort.order !== 'DESC' ) && (
        <Button color="error" label="triage" onClick={() => setSort({ field: 'created_at', order: 'DESC' })}><CancelIcon /></Button>
      )}

      <IfCanAccess action="create">
        <CreateButton label="Créer un utilisateur" />
      </IfCanAccess>

      <ImportProfileButton {...props} />

      { Object.keys(filterValues).length > 0 && (
        <Button color="error" label="filtres" onClick={() => setFilters({})}><CancelIcon /></Button>
      )}
    </TopToolbar>
  )
}

const PostBulkActionButtons = props => {
  const refresh = useRefresh()
  const [updateMany, { isLoading }] = useUpdateMany()

  const enabledOrDisabled = action => {
    updateMany('profiles',
      {
        ids: props.selectedIds,
        data: { enabled: action }
      },
      {
        onSuccess: () => {
          refresh();
        },
      }
    )
  }

  return (
    <Fragment>
      <Button label="Activer" onClick={() => enabledOrDisabled(true)} disabled={isLoading} />
      <Button label="Désactiver" onClick={() => enabledOrDisabled(false)} disabled={isLoading} />
      <BulkDeleteWithConfirmButton />
    </Fragment>
  )
}

const ProfilesListToolbar = props => {
  // create a padding when selected ids
  const { selectedIds } = useListContext()

  return (
    <Stack direction="row" style={selectedIds.length > 0 ? { padding: '0 10px 48px 10px' } : { padding: '0 10px' }}></Stack>
  )
}

const ProfileList = props => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  return (
    <>
      <List
        {...props}
        aside={<ProfileListFilter />}
        actions={<CustomActions />}
        pagination={<PostPagination />}
        perPage={50}
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        <ProfilesListToolbar />
        
        <Datagrid rowClick="edit" bulkActionButtons={<PostBulkActionButtons />}>
          <BooleanField label="Actif" source="enabled" valueLabelTrue="Oui" valueLabelFalse="Non" />

          <TextField label="Nom" source="last_name" />
          
          <TextField label="Prénom" source="first_name" />
          
          <EmailField label="Email" source="email" />
          
          <ReferenceField label="Rôle" source="role_id" reference="roles">
            <TextField source="name" />
          </ReferenceField>

          <NumberField label="Contacts" source="leadcount" />

          { isAdmin &&
            <ReferenceField label="Centre" source="center_id" reference="centers">
              <TextField source="name" />
            </ReferenceField>
          }
        </Datagrid>
      </List>

      <ProfileShow />
      <ProfileEdit />
      <ProfileCreate />
    </>
  )
}

export default ProfileList;

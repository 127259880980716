import {
  useListContext,
  AutocompleteInput,
  FilterForm,
  ReferenceInput,
  SearchInput,
  useGetIdentity,
  FilterListItem,
  FilterList,
  TextInput,
} from 'react-admin'
import {
  Stack,
  TextField,
  Autocomplete
} from '@mui/material'

import GetData from '../common/GetData'
import { getRole } from '../common/roles'
import { useEffect, useState } from 'react'
import CustomSearchInput from '../Design/CustomSearchInput'
import { filterProfileBlankSpaces } from '../common/customTextFilter'

const ListToolbar = () => {
  const inputStyle = { maxWidth: '150px' }
  const { identity } = useGetIdentity()
  const { filterValues, selectedIds, setFilters } = useListContext()

  const centersData = GetData('centers', '50', 'name', 'ASC')

  const centerChoices = centersData?.map(center => ({
    label: center.name,
    value: {
      name: center.name,
      id: center.id,
    },
    selected: false,
  })) || []

  identity?.role?.grade >= getRole('responsable') && centerChoices.unshift (
    {
      label: 'Sans centre',
      value: {
        name: 'Sans centre',
        id: '9999',
      },
      selected: false,
    }
  )

  const handleAutocompleteChange = (newValue) => {
    newValue
      ? localStorage.setItem('selectedOption', JSON.stringify(newValue))
      : localStorage.removeItem('selectedOption')

    setFilters({ ...filterValues, 'center_id': newValue?.value?.id })
    setSelectedValue(newValue)
  }

  const [selectedValue, setSelectedValue] = useState(null)

  useEffect(() => {
    const selectedOption = JSON.parse(localStorage.getItem('selectedOption')) ?? null
    setSelectedValue(selectedOption)

    if ( ! filterValues.center_id ) {
      setSelectedValue(null)
    }
  }, [filterValues])

  const LeadsFilter = [
    <SearchInput placeholder="Prénom" source="first_name@ilike" variant="outlined" timeout={5000} key="searchFirstName" alwaysOn sx={inputStyle} />,
    <SearchInput placeholder="Nom" source="last_name@ilike" variant="outlined" timeout={5000} key="searchLastName" alwaysOn sx={inputStyle} />,
    <SearchInput placeholder="Téléphone" source="phone@like" variant="outlined" timeout={5000} key="searchPhone" alwaysOn sx={inputStyle} />,
    // filtrer par centre !
    identity?.role?.grade >= getRole('responsable') && (
      <ReferenceInput
        source="assigned_pubeur"
        reference="profiles"
        alwaysOn
      >
        <AutocompleteInput
          label="Pubeur"
          variant="outlined"
          optionText={choice => `${choice?.first_name ?? ''} ${choice?.last_name ?? ''}`}
          filterToQuery={filterProfileBlankSpaces}
        />
      </ReferenceInput>
    ),

    identity?.role?.grade === getRole('admin') && (
      <ReferenceInput
        source="center_id"
        reference="centers"
        fullWidth
        alwaysOn
      >
        <Autocomplete
          disablePortal
          includeInputInList
          value={selectedValue ?? null}
          options={centerChoices}
          isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
          sx={{ minWidth: '200px' }}
          onChange={(event, newValue) => handleAutocompleteChange(newValue)}
          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Choisir un centre" />}
        />
      </ReferenceInput>
    ),
  ].filter(Boolean)

  return (
    <Stack direction="row" style={selectedIds.length > 0 ? { padding: '0 10px 50px 10px' } : { padding: '0 10px' }}>
      <FilterForm filters={LeadsFilter} />

      <FilterList label="">
        <FilterListItem
          label="Excl. Stop RGPD"
          sx={{ minWidth: '180px', padding: '5px 0px', borderRadius: '6px', border: '1px solid #cecece' }}
          value={{ stop_rgpd:
            { 1: {
              filterMethod: false,
            }}
          }}
        />
      </FilterList>
    </Stack>
  )
}

export default ListToolbar
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { supabaseClient as supabase } from './supabase'
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material'

const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [isTokenValid, setIsTokenValid] = useState(false)

  useEffect(() => {
    const validateToken = async () => {
      const params = new URLSearchParams(location.search)
      const token = params.get('2C4-P6B_4LQ75YM')

      if (!token) {
        navigate('/login')
        return
      }

      const { error } = await supabase.auth.verifyOtp({ token_hash: token, type: 'email'})

      if (error) {
        setError('Accès invalide ou expiré. Veuillez contactez un responsable.')
        console.error('Error validating token:', error.message)
        return
      }

      setIsTokenValid(true)
    }

    validateToken()
  }, [location.search])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    setError('')

    if (!isTokenValid) {
      setLoading(false)
      return
    }

    if (password !== passwordConfirm) {
      setError('Les mots de passe ne correspondent pas.')
      setLoading(false)
      return
    }

    try {
      const { error } = await supabase.auth.updateUser({ password: password })

      if (error) {
        setError(error.message)
      } else {
        navigate('/login')
      }
    } catch (error) {
      console.error('Error updating password:', error.message)
      setError('Une erreur est survenue. Veuillez contacter un responsable.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth="sm" sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'top',
      alignItems: 'center',
      height: '100vh',
      paddingTop: '10vh',
      backgroundColor: '#f4f4f4',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    }}>
      <Typography variant="h2" sx={{ fontWeight: 900 }} gutterBottom>Forma Contacts</Typography>

      <Typography variant="h5" gutterBottom>
        Réinitialisez votre mot de passe
      </Typography>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Nouveau mot de passe"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          label="Confirmer le mot de passe"
          type="password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        { ! error && (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? 'Enregistrement...' : 'Continuer'}
          </Button>
        )}
      </Box>
    </Container>
  )
}

export default ResetPasswordPage
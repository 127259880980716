import * as React from "react"
import { Typography, Stack, Button } from "@mui/material"
import Card from '@mui/material/Card'

export default () => {
  return (
    <Card sx={{ mt: 4, p: 2, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        Mon tableau de bord
      </Typography>

      {/* <Stack spacing={2} mt={4} direction="row" justifyContent="space-between"> */}
      <Stack spacing={2} mt={4} direction="column" alignItems="center">
        <img
          src="https://images.klipfolio.com/website/public/5a275fee-d42b-4f31-91f6-8148d4d729af/executive%20dashboard.png"
          alt="dashboard"
          style={{ maxWidth: '400px' }}
        />

        <Typography variant="h5" sx={{ padding: '50px 0 50px 0' }}>
          Bientôt, votre tableau de pilotage s'affichera ici.
        </Typography>

        <Button variant="contained" color="primary" sx={{ width: '300px' }} size="large" onClick={
          () => { window.location.href = '/leads' }
        }>
          Voir tous les contacts
        </Button>
      </Stack>
    </Card>
  )
}
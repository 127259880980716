import { EditDialog } from '@react-admin/ra-form-layout'

import LeadRDV from './LeadRDV'
import LeadForm from './LeadForm'
import LeadTasks from './leadsTasks'
import LeadParcours from './LeadParcours'
import LeadComments from './LeadComments'
import { LeadFavourite } from './LeadFavourite'
import { CustomLeadsTab, CustomLeadTabbedShowLayout } from '../Design/CustomTabs'

const LeadEdit = () => (
  <EditDialog
    title={<LeadFavourite />}
    fullWidth
    maxWidth="md"
    mutationMode="optimistic"
    sx={{
      '& .MuiDialogTitle-root': {
        backgroundColor: '#d7eae8',
      },
    }}
  >
    <CustomLeadTabbedShowLayout sx={{ minHeight: '90vh' }}>
      <CustomLeadsTab label="Fiche contact">
        <LeadForm action="edit" />
      </CustomLeadsTab>

      <CustomLeadsTab label="Parcours" path="history">
        <LeadParcours />
      </CustomLeadsTab>

      <CustomLeadsTab label="Commentaires" path="comments">
        <LeadComments />
      </CustomLeadsTab>

      <CustomLeadsTab label="Tâches" path="tasks">
        <LeadTasks />
      </CustomLeadsTab>

      <CustomLeadsTab label="RDV" path="rdv">
        <LeadRDV />
      </CustomLeadsTab>
    </CustomLeadTabbedShowLayout>
  </EditDialog>
)

export default LeadEdit

import { useMemo } from "react"
import {
  useGetIdentity,
  DatagridConfigurable,
  DateField,
  FunctionField,
  ReferenceField,
  List,
  useRecordContext
} from 'react-admin'

import LeadEdit from './LeadEdit'
import LeadCreate from './LeadCreate'
import { getRole } from '../common/roles'
import { classes } from '../common/classes'
import ListToolbar from './LeadsListToolbar'
import { exporter } from './LeadModelExport'
import { LeadActions } from './LeadsTopToolbar'
import BulkMenu from './bulkComponents/BulkMenu'
import { PostPagination } from '../common/Pagination'
import LeadsAsideFilters from './filters/LeadsAsideFilters'
import DisplayPhoneNumber from '../Design/DisplayPhoneNumber'
import { InfoSelectField } from '../Design/CustomSelectField'
import { InfoTextField, YellowTextField, FavouriteTextField, TasksTextField, TruncatedTextField } from '../Design/CustomTextField'
import { InfoTextReferenceField, SourceTextReferenceField, YellowReferenceField } from '../Design/CustomReferenceField'

import TripOriginIcon from '@mui/icons-material/TripOrigin'
import StarPurple500Icon from '@mui/icons-material/StarPurple500'
import customColor from "../common/customColor"

const LeadList = props => {
  const { identity } = useGetIdentity()
  const localCenter = identity?.center
  const localCurrentYear = JSON.parse(localStorage.getItem('localCurrentYear'))?.id || process.env.REACT_APP_CURRENT_YEAR_ID

  const exporterProp = useMemo(() => exporter ?? exporter, [props.exporter])

  const postRowSx = (record) => {
    // const { textColor, bgColor } = customColor(record)

    // return {
    //   color: textColor,
    //   backgroundColor: bgColor,
    // }
  }

  return (
    <>
      <List
        {...props}
        pagination={<PostPagination />}
        perPage={50}
        actions={<LeadActions exporter={exporterProp} {...props} />}
        sort={{ field: 'updated_at', order: 'DESC' }}
        empty={false}
        aside={<LeadsAsideFilters />}
      >
        <ListToolbar />

        { identity?.role?.grade === getRole('admin') && (
          <DatagridConfigurable
            preferenceKey="admin.datagrid"
            rowClick="edit"
            omit={ ['created_at', 'updated_at', 'email', 'school_id', 'center_id'] }
            bulkActionButtons={<BulkMenu />}
            rowSx={postRowSx}
          >
            <SourceTextReferenceField textFieldSource="name" label={<TripOriginIcon sx={{ fontSize: '1rem', color: '#999' }} />} source="lead_source_id" reference="lead_sources" />

            <InfoTextField source="first_name" info="first_name" label="Prénom" />

            <InfoTextField source="last_name" info="last_name" label="Nom" />
            
            <DisplayPhoneNumber source="phone" label="Teléphone"/>
            
            <YellowTextField source="email" info="email" />
            
            <InfoSelectField source="class_id" label="Classe" choices={classes[localCenter?.id]?.options ?? classes['default']?.options} />
            
            <InfoTextReferenceField textFieldSource="name" label="Lycée" source="school_id" reference="schools" truncate="15" />
            
            <ReferenceField label="Centre" source="center_id" reference="centers">
              <FunctionField render={record => (
                record?.name
              )} />
            </ReferenceField>

            <YellowReferenceField label="Pubeur" source="assigned_pubeur" reference="profiles">
              <FunctionField render={record => { return record ? (
                `${record.first_name} ${record.last_name}`
              ) : null } } />
            </YellowReferenceField>

            <TasksTextField label="Tâches" source="tasks_info" />
            
            <DateField label="Date de création" source="created_at" showTime={true} />
            
            <DateField label="Date de modification" source="updated_at" showTime={true} />

            <InfoTextReferenceField source="status_inscription_id" textFieldSource="name" label="Statut inscr."  reference="qualifications" link={false} />

            <TruncatedTextField source="last_comment" label="Commentaire" />

            <FavouriteTextField source="favourited_by" label={<StarPurple500Icon sx={{ fontSize: '1.65rem', color: '#999', ml: -.3 }} />} />
          </DatagridConfigurable>
        )}

        { identity?.role?.grade === getRole('responsable') && (
          <DatagridConfigurable
            preferenceKey="respo.datagrid"
            rowClick="edit"
            omit={ ['created_at', 'updated_at', 'email', 'school_id'] }
            bulkActionButtons={<BulkMenu />}
            rowSx={postRowSx}
          >
            <SourceTextReferenceField textFieldSource="name" label={<TripOriginIcon sx={{ fontSize: '1rem', color: '#999', width: '5%' }} />} source="lead_source_id" reference="lead_sources" />

            <InfoTextField source="first_name" info="first_name" label="Prénom" sx={{ width: '10%' }} />

            <InfoTextField source="last_name" info="last_name" label="Nom" sx={{ width: '10%' }} />
            
            <DisplayPhoneNumber source="phone" label="Teléphone" sx={{ width: '12%' }}/>
            
            <YellowTextField source="email" info="email" sx={{ width: '15%' }} />
            
            <InfoSelectField source="class_id" label="Classe" choices={classes[localCenter?.id]?.options ?? classes['default']?.options} />
            
            <InfoTextReferenceField textFieldSource="name" label="Lycée" source="school_id" reference="schools" truncate="15" />

            <YellowReferenceField label="Pubeur" source="assigned_pubeur" reference="profiles">
              <FunctionField render={record => { return record ? (
                `${record.first_name} ${record.last_name}`
              ) : null } } />
            </YellowReferenceField>

            <TasksTextField label="Tâches" source="tasks_info" />
            
            <DateField label="Date de création" source="created_at" showTime={true} />
            
            <DateField label="Date de modification" source="updated_at" showTime={true} />

            <InfoTextReferenceField source="status_inscription_id" textFieldSource="name" label="Statut inscr."  reference="qualifications" link={false} />

            <TruncatedTextField source="last_comment" label="Commentaire" />

            <FavouriteTextField source="favourited_by" label={<StarPurple500Icon sx={{ fontSize: '1.65rem', color: '#999', ml: -.3 }} />} />
          </DatagridConfigurable>
        )}

        { identity?.role?.grade < getRole('responsable') && (
          <DatagridConfigurable
            preferenceKey="pubeur.datagrid"
            rowClick="edit"
            omit={ ['email', 'created_at', 'updated_at'] }
            bulkActionButtons={false}
            rowSx={postRowSx}
          >
            <SourceTextReferenceField textFieldSource="name" label={<TripOriginIcon sx={{ fontSize: '1rem', color: '#999' }} />} source="lead_source_id" reference="lead_sources" />

            <InfoTextField source="first_name" info="first_name" label="Prénom" />

            <InfoTextField source="last_name" info="last_name" label="Nom" />
            
            <DisplayPhoneNumber source="phone" label="Teléphone"/>
            
            <YellowTextField source="email" info="email" />
            
            <InfoSelectField source="class_id" label="Classe" choices={classes[localCenter?.id]?.options ?? classes['default']?.options} />
            
            <InfoTextReferenceField label="Lycée" source="school_id" reference="schools" truncate="15" textFieldSource="name" />
            
            <DateField label="Date de création" source="created_at" showTime={true} />
            
            <DateField label="Date de modification" source="updated_at" showTime={true} />

            <InfoTextReferenceField source="status_inscription_id" textFieldSource="name" label="Statut inscr."  reference="qualifications" link={false} />

            <TasksTextField label="Tâches" source="tasks_info" />
            
            <TruncatedTextField source="last_comment" label="Commentaire" />

            <FavouriteTextField source="favourited_by" label={<StarPurple500Icon sx={{ fontSize: '1.65rem', color: '#999', ml: -.3 }} />} />
          </DatagridConfigurable>
        )}
      </List>
      
      <LeadEdit />
      { localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID && <LeadCreate /> }
    </>
  )
}

export default LeadList
